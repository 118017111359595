import React from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import cookieUtils from '../../utils/cookie';
import constantCommon from '../../constants/common';
import actions from '../../actions';
function Languages (props) {
    const dispatch = useDispatch();
    let currentLanguage = cookieUtils.get('language');
    if (currentLanguage === "") {
        currentLanguage = constantCommon.LANGUAGE_DEFAULT;
    }
    const fetchCurrentLanguage = props.langs.filter(lang => lang.code === currentLanguage);
    return <Dropdown className="float-right">
        { fetchCurrentLanguage.length > 0 && (
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                { fetchCurrentLanguage[0].name }
            </Dropdown.Toggle>
        )}

        <Dropdown.Menu>
            {props.langs.map((lang) => (
                <Dropdown.Item key={lang.code} onClick={() => {
                    cookieUtils.set('language', lang.code);
                    dispatch(actions.loadLocalization(lang.code));
                    window.location.reload();
                }}>
                    {lang.name}
                </Dropdown.Item>
            ))}
        </Dropdown.Menu>
    </Dropdown>
}

export default Languages;
