export default {
    LANGUAGE_DEFAULT: 'en',
    SEARCH_TYPE_FOR_SALE: "ForSale",
    SEARCH_TYPE_FOR_RENT: "ForRent",
    SEARCH_TYPE_FOR_RENT_L: "LongTermRental",
    SEARCH_TYPE_FOR_RENT_S: "ShortTermRental",

    SEARCH_VERSION_20: "2.0",
    SEARCH_VERSION_21: "2.1",
    SEARCH_ENGINE_CORE_API: "CoreApi",
    SEARCH_ENGINE_RESALES_PLUS: "ResalesPlus",
}
