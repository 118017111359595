export default {
    title: "{agencyName}: n ominaisuudet",
    in: "sisään",
    propertiesFound: "löydetyt ominaisuudet",
    toDay: "Tänään",
    days: "päivää",
    week: "Viikko",
    year: "Vuosi",
    month: "Kuukausi",
    prev: "Edellinen",
    next: "Seuraava",
    backToList: "Takaisin luetteloon",
    sorry: "Anteeksi, me",
    cantFind: "ei löydy",
    lookingFor: "etsimäsi sivu ...",
    thankYou: "KIITOS KÄYNNISTÄ SIVUSIVULLA",
    pleaseContact: "Ota yhteys edustajaan saadaksesi uuden URL-osoitteen.",
    ForSale: "ALE",
    ShortTermRental: "Lyhyt vuokra",
    LongTermRental: "Long Rent",
    showMore: "Show More",
    showLess: "Näytä vähemmän",
    price: "Hinta",
    pricePer: "Hinta per",
    built: "Rakenna",
    floorSpace: "Int. lattiatilaa",
    terrace: "Terassi",
    garden: "Puutarha",
    plot: "juoni",
    propTitle: '{subType} {searchType} kohteessa {location}',
    propTitleShort: '{subType} kohteessa {location}',
    notAvailable: "Ei saatavilla",
    notFound: 'Sivua ei löydy',
    emptyProperties: "Ominaisuuksia ei löytynyt, ota yhteyttä edustajaasi saadaksesi lisätietoja.",
    linkExpired: "Tämä linkki on vanhentunut. Ota yhteyttä kiinteistönvälittäjään saadaksesi päivitetyn kiinteistölinkin.",
    newDevelopment: "Uusi kehitys",
    agencyRaiiNo: "Viraston rekisteröintinumero",
    home: {
        verifyYourURLHere: "Vahvista URL-osoitteesi täällä. Voit tehdä tämän kirjautumalla sisään selaimesi WhatsAppiin ja kopioimalla sitten URL-osoitteen WhatsAppista tähän.",
        pasteUrlHere: "Liitä URL tähän",
        verifyUrl: "Vahvista URL",
        whatIsPropertyViewer: "Mikä on Property-Viewer.com?",
        thisIsWebsiteIsUsed: "Kiinteistönvälittäjät käyttävät tätä verkkosivustoa lähettääkseen kiinteistöjä asiakkailleen. Se on sivuston {resalesonlinecom} tuote. Jos olet epävarma, ota yhteyttä agentti, joka lähetti omaisuusluettelon sinulle.",
        howDoesItWork: "Miten se toimii?",
        inThePast: "Aiemmin agentit lähettäisivät PDF-dokumentteja asiakkailleen. Näitä ei kuitenkaan ole helppo tarkastella monissa laitteissa. Siksi loimme {propertyviewercom}, jotta asiakas voi helpommin tarkastella heille lähetettyjä ominaisuuksia.",
        createAShortList: "Asiakaspalvelija luo lyhyen ominaisuuksien luettelon. Kun he jakavat nämä ominaisuudet kanssasi, saat linkin. Napsauttamalla tätä linkkiä näet ominaisuudet."
    },

    list: {
        propertyTitle: "Napsauttamalla tätä voit tarkastella tämän ominaisuuden tietoja"
    },

    searchType: {
        ForSale: "myytävänä",
        ShortTermRental: "lyhytaikaiseen vuokraukseen",
        LongTermRental: "pitkäaikaiseen vuokraukseen",
    },

    rsStatus: {
        Available: "Käytettävissä",
        Listing: "Listaus",
        UnderOffer: "Alla Tarjous",
        SaleAgreed: "Myynti sovittu",
        OffMarket: "Markkinoiden edustalla",
        Sold: "Myyty",
        Rented: "Vuokrata"
    },

    details: {
        average: "keskiarvo",
        averagePricesFor: "Keskimääräiset hinnat kohteelle",
        showFullER: "Näytä täysi",
        thisProperty: "Tämä ominaisuus",
        infoNotAvailable: "Tätä tietoa ei ole saatavilla.",
        feesEnergyRating: "Maksut, energialuokitus",
        fees: "Palkkiot",
        ibiFees: "IBI-palkkiot",
        basuraTax: "Basuravero",
        communityFees: "Yhteisön palkkiot",
        associatedCosts: "Liittyvät kustannukset",
        energyRating: "Energialuokitus",
        securityDeposit: "Vuokravakuus",
        energyCertificate: "Energiasertifikaatti",
        energyCertificatePending: "Energiasertifikaatti odottaa",
        energyConsumption: "energiankulutus",
        co2: "CO₂",
        co2PerYear: "kgCO₂ / m² vuodessa",
        kwhPerYear: "kWh / m² vuodessa",
        other: "Toiset",
        buildYear: "Rakennusvuosi",
        forSale: "Myytävänä",
        sold: "Myyty",
        longTerm: "Pitkäaikainen",
        shortTermHigh: "Lyhytaikainen korkea",
        shortTermLow: "Lyhytaikainen matala",
        allFeatures: "Kaikki ominaisuudet",
        aproxLocation: "Aprox. Sijainti",
        listingAgent: "Listing agent",
        notIncludeExpensesAndTaxes: "El precio no incluye gastos ni tributos. Los gastos adicionales al precio por parte del comprador son: Vivienda nueva/1ª Transmission Impuesto sobre el Valor Añadido (IVA) 10% (o 4% en caso de vivienda protegida); o en el caso de 2ª y/o Posteriores Transmisiones Impuesto Sobre Transmisiones (ITP) 7%. Además, la formalización en documento público del contrato de compraventa (escrituras) está sujeta a AJD Actos Jurídicos Documentados 1,2% sobre el precio de la compra, gastos de inscripción en el registro y notaría. Tiene a su disposición copia de la correspondiente ficha informativa de esta propiedad en nuestra oficina según Decreto 218/2005 de 11 de octubre.",
        rentalLicenseRef: "Vuokrauslisenssin viite",
        agencyInformation: "Viraston tiedot",
    },
};