import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import actions from '../../../actions';
import urlUtils from '../../../utils/url';

export default (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const properties = useSelector((state) => {
        return state.properties;
    });

    const uuid = properties.uuid;
    const currentProperty = properties.currentProperty;

    let propIndex = properties.properties.findIndex((elem) => {
        return elem.RsId === currentProperty.RsId;
    });
    const propsCount = properties.properties.length;

    const l = useSelector((state) => {
        return state.localizations.l;
    });

    const moveToProp = async (index) => {
        let nextProp = properties.properties[index];
        history.push(urlUtils.getUrl(uuid, nextProp.RsId));
        dispatch(actions.setDetail(nextProp))
    };

    const backToList = () => {
        history.push(urlUtils.getUrl(uuid));
        dispatch(actions.backToList());
    };

    const next = () => {
        moveToProp(propIndex+1);
    };

    const prev = () => {
        moveToProp(propIndex-1);
    };

    return (
        <Row>
            <Col className="prev">
                <Button disabled={ propIndex === 0 } onClick={ () => prev() }>
                    <i className="icon icon-proe-prev"/> { l.prev }</Button>
            </Col>
            <Col>
                <Button className="back" onClick={ () => backToList() }>
                    <i className="icon icon-proe-adv-search"/> { l.backToList }</Button>
            </Col>
            <Col className="next">
                <Button disabled={ propIndex === (propsCount-1) } onClick={ () => next() }>
                    { l.next } <i className="icon icon-proe-next"/></Button>
            </Col>
        </Row>
    )
}
