import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from "react-redux";

function DetailsEnergyRatingModal(props) {
    const l = useSelector((state) => {
        return state.localizations.l;
    });

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button className="erShowfull asLink" onClick={ handleShow }>
                { l.details.showFullER } <i className="icon icon-proe-popup"/></Button>

            <Modal size="md" show={ show } onHide={ handleClose }>
                <Modal.Header closeButton>
                    <Modal.Title>{ l.details.energyConsumption }</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <img alt="Energy Rating" style={{ width:"100%" }} src={ props.energyRatingMedia } />
                </Modal.Body>
            </Modal>
        </>
  );
}

export default DetailsEnergyRatingModal;
