import configApis from './configs/apis';

const superagent = require('superagent');
const responseBody = (res) => res.body;
const environment = configApis.environment;
const DEV_ENVIRONMENT = 'development';

export default {
    get: (url) => {
        let agentGet = superagent.get(url);

        if (environment !== DEV_ENVIRONMENT) {
            agentGet.withCredentials();
        }

        return agentGet.then(responseBody);
    },

    post: (url, data) => {
        let agentPost = superagent.post(url);

        agentPost.send(data);
        if (environment !== DEV_ENVIRONMENT) {
            agentPost.set('x-api-key', configApis.x_api_key);
            agentPost.withCredentials();
        }
        agentPost.then(responseBody);
    },
};
