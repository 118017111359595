import { combineReducers, createStore } from 'redux';
import agencies from './reducers/agencies';
import properties from './reducers/properties';
import localizations from './reducers/localizations';
const rootReducer = combineReducers({
    properties,
    agencies,
    localizations
})

export default createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
