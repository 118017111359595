import Converter from '../constants/converters';

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
};

function formatNumber(number, decimalCount) {
  return Number(number/100).toFixed(decimalCount)
};

function formatCurrency(number, currency) {
    number = parseInt(number).formatMoney( 0, ',', '.')
    const currencySymbol = Converter.CURRENCY[currency]

    return currency === "EUR" ? `${number} ${currencySymbol}` : `${currencySymbol} ${number}`
}

export default {
    formatMoney,
    formatNumber,
    formatCurrency
}
