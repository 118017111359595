export default {
    title: "{agencyName}'s egenskaber",
    in: "in",
    propertiesFound: "ejendomme fundet",
    toDay: "I dag",
    days: "dage",
    week: "Uge",
    year: "År",
    month: "Måned",
    prev: "Forrige",
    next: "Næste",
    backToList: "Tilbage til listen",
    sorry: "Undskyld, vi",
    cantFind: "kan ikke finde",
    lookingFor: "den side, du leder efter ...",
    thankYou: "TAK FOR BESØG VORES WEBSITE",
    pleaseContact: "Kontakt agenten for en ny URL.",
    ForSale: "SALG",
    ShortTermRental: "Kort leje",
    LongTermRental: "Lang leje",
    showMore: "Vis mere",
    showLess: "Vis mindre",
    price: "Pris",
    pricePer: "Pris pr.",
    built: "Byg",
    floorSpace: "Kraterbunden plads",
    terrace: "Terrasse",
    garden: "Have",
    plot: "Grundstykke",
    propTitle: '{subType} {searchType} i {location}',
    propTitleShort: '{subType} i {location}',
    notAvailable: "Ikke tilgængelig",
    notFound: 'Side ikke fundet',
    emptyProperties: "Ingen ejendomme blev fundet. Kontakt din agent for mere information.",
    linkExpired: "Ups, dette link er udløbet. Kontakt din ejendomsmægler for at modtage et opdateret ejendomslink.",
    newDevelopment: "Ny udvikling",
    agencyRaiiNo: "Agenturets registreringsnummer",
    home: {
        verifyYourURLHere: "Bekræft din URL her. For at gøre dette skal du logge ind på WhatsApp i din webbrowser og derefter kopiere URL'en fra WhatsApp til her.",
        pasteUrlHere: "Indsæt URL her",
        verifyUrl: "Bekræft URL",
        whatIsPropertyViewer: "Hvad er Property-Viewer.com?",
        thisIsWebsiteIsUsed: "Dette websted bruges af ejendomsmæglere til at sende ejendomme til deres kunder. Det er et produkt fra {resalesonlinecom} Hvis du er i tvivl, bedes du tale med agenten, der sendte din ejendomsliste til dig.",
        howDoesItWork: "Hvordan virker det?",
        inThePast: "Tidligere ville Agenter sende PDF-dokumenter til deres kunder. Men disse er ikke nemme at se på mange enheder. Derfor har vi {propertyviewercom}, så klienten lettere kan få vist egenskaber, der er sendt til dem.",
        createAShortList: "Agenten opretter en kort liste over egenskaber. Når de deler disse egenskaber med dig, vil du modtage et link. Hvis du klikker på dette link vil vise dig de egenskaber."
    },

    searchType: {
        ForSale: "til salg",
        ShortTermRental: "til korttidsleje",
        LongTermRental: "til langtidsleje",
    },

    rsStatus: {
        Available: "Tilgængelig",
        Listing: "Notering",
        UnderOffer: "Under tilbud",
        SaleAgreed: "Salg aftalt",
        OffMarket: "Uden for markedet",
        Sold: "Solgt",
        Rented: "Lejet"
    },


    list: {
        propertyTitle: "Klik her for at få vist oplysninger om denne egenskab"
    },

    details: {
        average: "Gennemsnit",
        averagePricesFor: "Gennemsnitlige priser for",
        showFullER: "Vis fuld",
        thisProperty: "Denne ejendom",
        infoNotAvailable: "Disse oplysninger er ikke tilgængelige.",
        feesEnergyRating: "Gebyrer, energivurdering",
        fees: "Gebyrer",
        ibiFees: "IBI-gebyrer",
        basuraTax: "Basura Tax",
        communityFees: "Fællesskabsgebyrer",
        associatedCosts: "Tilknyttede omkostninger",
        energyRating: "Energimærkning",
        securityDeposit: "Depositum",
        energyCertificate: "Energicertifikat",
        energyCertificatePending: "Energicertifikatet afventer",
        energyConsumption: "Energiforbrug",
        co2: "CO₂",
        co2PerYear: "kgCO₂/m² pr. år",
        kwhPerYear: "kWh/m² pr. år",
        other: "Andere",
        buildYear: "Baujahr",
        forSale: "Til salg",
        sold: "Solgt",
        longTerm: "Langsigtet",
        shortTermHigh: "Short term high",
        shortTermLow: "Kortvarig lav",
        allFeatures: "Alle funktioner",
        aproxLocation: "Aprox. Placering",
        listingAgent: "Noteringsagent",
        notIncludeExpensesAndTaxes: "El precio no incluye gastos ni tributos. Los gastos adicionales al precio por parte del comprador son: Vivienda nueva/1ª Transmission Impuesto sobre el Valor Añadido (IVA) 10% (o 4% en caso de vivienda protegida); o en el caso de 2ª y/o Posteriores Transmisiones Impuesto Sobre Transmisiones (ITP) 7%. Además, la formalización en documento público del contrato de compraventa (escrituras) está sujeta a AJD Actos Jurídicos Documentados 1,2% sobre el precio de la compra, gastos de inscripción en el registro y notaría. Tiene a su disposición copia de la correspondiente ficha informativa de esta propiedad en nuestra oficina según Decreto 218/2005 de 11 de octubre.",
        rentalLicenseRef: "Mietlizenz Ref",
        agencyInformation: "Oplysninger om agenturet",
    },
};