export default {
    title: "Eigenschaften von {agencyName}",
    in: "im",
    propertiesFound: "eigenschaften gefunden",
    toDay: "Heute",
    days: "tage",
    week: "Woche",
    year: "Jahr",
    month: "Monat",
    prev: "Zurück",
    next: "Nächster",
    backToList: "Zurück zur Liste",
    sorry: "Entschuldigung, wir",
    cantFind: "kann nicht finden",
    lookingFor: "die Seite, die Sie suchen ...",
    thankYou: "Vielen Dank für Ihren Besuch auf unserer Website",
    pleaseContact: "Bitte kontaktieren Sie den Agenten für eine neue URL.",
    ForSale: "VERKAUF",
    ShortTermRental: "Kurze Miete",
    LongTermRental: "Lange Miete",
    showMore: "Zeig mehr",
    showLess: "Zeige weniger",
    price: "Preis",
    pricePer: "Preis pro",
    built: "Bauen",
    floorSpace: "Innere Bodenfläche",
    terrace: "Terrasse",
    garden: "Garten",
    plot: "Handlung",
    propTitle: '{subType} {searchType} im {location}',
    propTitleShort: '{subType} im {location}',
    notAvailable: "Nicht verfügbar",
    notFound: 'Seite nicht gefunden',
    emptyProperties: "Es wurden keine Immobilien gefunden. Bitte wenden Sie sich an Ihren Agenten, um weitere Informationen zu erhalten.",
    linkExpired: "Oops, dieser Link ist abgelaufen. Wenden Sie sich an Ihren Immobilienmakler, um einen aktualisierten Immobilienlink zu erhalten.",
    newDevelopment: "Neuentwicklung",
    agencyRaiiNo: "Registrierungsnummer der Agentur",
    home: {
        verifyYourURLHere: "Überprüfen Sie hier Ihre URL. Melden Sie sich dazu in Ihrem Webbrowser bei WhatsApp an und kopieren Sie die URL von WhatsApp hierher.",
        pasteUrlHere: "URL hier einfügen",
        verifyUrl: "Überprüfen Sie die URL",
        whatIsPropertyViewer: "Was ist Property-Viewer.com?",
        thisIsWebsiteIsUsed: "Diese Website wird von Immobilienmaklern verwendet, um Immobilien an ihre Kunden zu senden. Es ist ein Produkt von {resalesonlinecom}. Im Zweifelsfall wenden Sie sich bitte an den Makler, der Ihnen Ihre Immobilienliste gesendet hat.",
        howDoesItWork: "Wie funktioniert es?",
        inThePast: "In der Vergangenheit haben Agenten PDF-Dokumente an ihre Kunden gesendet. Diese sind jedoch auf vielen Geräten nicht einfach zu sehen. Daher haben wir {propertyviewercom} erstellt, damit der Client die an sie gesendeten Eigenschaften leichter anzeigen kann.",
        createAShortList: "Der Agent erstellt eine kurze Liste von Eigenschaften. Wenn sie diese Eigenschaften mit Ihnen teilen, erhalten Sie einen Link. Wenn Sie auf diesen Link klicken, werden die Eigenschaften angezeigt."
    },

    list: {
        propertyTitle: "Klicken Sie hier, um Informationen für diese Eigenschaft anzuzeigen"
    },

    searchType: {
        ForSale: "zu verkaufen",
        ShortTermRental: "für kurzfristige Miete",
        LongTermRental: "für langfristige Miete",
    },

    rsStatus: {
        Available: "Verfügbar",
        Listing: "Auflistung",
        UnderOffer: "Unter Angebot",
        SaleAgreed: "Verkauf vereinbart",
        OffMarket: "Außerhalb des Marktes",
        Sold: "Verkauft",
        Rented: "Gemietet"
    },

    details: {
        average: "Durchschnittlich",
        averagePricesFor: "Durchschnittspreise für",
        showFullER: "Voll anzeigen",
        thisProperty: "Dieses Anwesen",
        infoNotAvailable: "Diese Informationen sind nicht verfügbar.",
        feesEnergyRating: "Gebühren, Energieeffizienzklasse",
        fees: "Gebühren",
        ibiFees: "IBI-Gebühren",
        basuraTax: "Basura Steuer",
        communityFees: "Gemeinschaftsgebühren",
        associatedCosts: "Verbundene Kosten",
        energyRating: "Energieeffizienzklasse",
        securityDeposit: "Kaution",
        energyCertificate: "Energiezertifikat",
        energyCertificatePending: "Das Energiezertifikat steht noch aus",
        energyConsumption: "Energieverbrauch",
        co2: "CO₂",
        co2PerYear: "kgCO₂/m² pro Jahr",
        kwhPerYear: "kWh/m² pro Jahr",
        other: "Andere",
        buildYear: "Baujahr",
        forSale: "Zu verkaufen",
        sold: "Verkauft",
        longTerm: "Langfristig",
        shortTermHigh: "Kurzfristig hoch",
        shortTermLow: "Kurzfristig niedrig",
        allFeatures: "Alle Features",
        aproxLocation: "Ca. Ort",
        listingAgent: "Listing agent",
        notIncludeExpensesAndTaxes: "El precio no incluye gastos ni tributos. Los gastos adicionales al precio por parte del comprador son: Vivienda nueva/1ª Transmission Impuesto sobre el Valor Añadido (IVA) 10% (o 4% en caso de vivienda protegida); o en el caso de 2ª y/o Posteriores Transmisiones Impuesto Sobre Transmisiones (ITP) 7%. Además, la formalización en documento público del contrato de compraventa (escrituras) está sujeta a AJD Actos Jurídicos Documentados 1,2% sobre el precio de la compra, gastos de inscripción en el registro y notaría. Tiene a su disposición copia de la correspondiente ficha informativa de esta propiedad en nuestra oficina según Decreto 218/2005 de 11 de octubre.",
        rentalLicenseRef: "Mietlizenz Ref",
        agencyInformation: "Informationen zur Agentur",
    },
};