import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import actions from '../../actions';
import common from "../../constants/common";
import propertyStatus from '../../constants/propertyStatus';
import { translatePropertyType } from '../../utils/common';
import format from '../../utils/format';
import propertyUtils from '../../utils/property';
import urlUtils from '../../utils/url';
import ListCarouseImage from './ListCarouselImage';

const Property = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const property = props.item;
    const uuid = useSelector(state => {
        return state.properties.uuid;
    });

    const isValidProperty = propertyUtils.isValidProperty(property.RsStatus, property.OwnProperty);

    function viewDetail() {
        if (isValidProperty) {
            history.push(urlUtils.getUrl(uuid, property.RsId));
            dispatch(actions.setDetail(property))
        }
    };

    function indexRsStatus() {
        if ([
            propertyStatus.UNDER_OFFER,
            propertyStatus.SALE_AGREED,
            propertyStatus.SOLD,
            propertyStatus.OFF_MARKET
        ].includes(property.RsStatus)) {
            return property.RsStatus.replace(/\s/g, '');
        }
        return "";
    }

    const l = useSelector((state) => {
        return state.localizations.l;
    });

    const searchInfo = useSelector(state => {
        return state.properties.searchInfo;
    });

    const translateData = useSelector(state => {
        return state.properties.propertyTypesTranslation;
    });

    const lng = useSelector(state => {
        return state.localizations.currentLanguage;
    });

    let subType = property.SubType;
    let rsIdText = 'R' + property.RsId;
    if (searchInfo.SearchEngine === common.SEARCH_ENGINE_RESALES_PLUS) {
        subType = translatePropertyType(translateData, property.Type, subType, lng)
    }


    const propTitle = l.formatString(l.propTitleShort, {
        subType: property.IsDevelopment ? l.newDevelopment : subType,
        location: property.RsLocation
    });

    const titleProperty = l.list.propertyTitle;
    const beds = parseInt(property.RsBeds, 10);
    const bedsTo = parseInt(property.RsBedsTo, 10);
    const baths = parseInt(property.RsBaths, 10);
    const bathsTo = parseInt(property.RsBathsTo, 10);

    return <div className={`property ${!isValidProperty ? "disabled" : ""}`}>
        <div className="feature-image">
            <div className="label-property-type">
                {indexRsStatus() !== "" && <span className={"RsStatus"}>{l.rsStatus[indexRsStatus()]}</span>}
                <span className={property.SearchType}>{l[property.SearchType]}</span>
            </div>
            <ListCarouseImage property={property} onViewDetail={viewDetail} />
        </div>
        <div className="property-info">
            <h3 title={titleProperty} className="property-title" onClick={viewDetail}>
                <span>{propTitle}</span>
            </h3>

            <div className="property-price">
                <span className="value">
                    <span>{format.formatCurrency(property.Price, property.Currency)}</span>
                    {property.Price < property.PriceTo && <span> - {format.formatCurrency(property.PriceTo, property.Currency)}</span>}
                    <span>
                        {property.SearchType === "LongTermRental"
                            ? "/" + l.month
                            : (
                                property.SearchType === "ShortTermRental"
                                    ? "/" + l.week
                                    : ""
                            )}
                    </span>
                </span>
            </div>

            <div className="extends-info">
                <Row className="row">
                    <Col xs={8}>
                        <div className="beds-baths">
                            {((beds > 0 && bedsTo >= 0) || (beds === 0 && bedsTo > 0)) ?
                                (<span className="property-beds">
                                    <i className="icon icon-proe-bedroom font-icon" />
                                    <span>
                                        {beds}
                                        {beds < bedsTo && " - " + bedsTo}
                                    </span>
                                </span>) : ''
                            }

                            {((baths > 0 && bathsTo >= 0) || (baths === 0 && bathsTo > 0)) ?
                                (<span className="property-baths">
                                    <i className="icon icon-proe-bathroom font-icon" />
                                    <span>
                                        {baths}
                                        {baths < bathsTo && " - " + bathsTo}
                                    </span>
                                </span>) : ''
                            }
                        </div>
                    </Col>
                    <Col xs={4}>
                        <div className="prop-ref pull-right">
                            <span>{rsIdText}</span>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
}

export default Property;
