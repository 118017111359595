import actionsType from '../constants/actionsType';

const initialState = {
    agency: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionsType.LOAD_AGENCY:
            return {
              ...state,
              agency: action.payload
            };

        default:
            return state;
    }
};
