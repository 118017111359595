export default {
    title: "{agencyName}'s properties",
    in: "en",
    propertiesFound: "propiedades encontradas",
    toDay: "Hoy",
    days: "dias",
    week: "Semana",
    year: "Año",
    month: "Mes",
    prev: "Anterior",
    next: "Siguiente",
    backToList: "Última búsqueda",
    sorry: "Lo sentimos",
    cantFind: "no puedo encontrar",
    lookingFor: "la página que estás buscando ...",
    thankYou: "GRACIAS POR VISITAR NUESTRO SITIO WEB",
    pleaseContact: "Comuníquese con el agente para obtener una nueva URL.",
    ForSale: "VENTA",
    ShortTermRental: "ALQUILER CORTO",
    LongTermRental: "ALQUILER LARGO",
    showMore: "Mostrar más",
    showLess: "Mostrar menos",
    price: "Precio",
    pricePer: "Precio por",
    built: "Construido",
    floorSpace: "Útiles",
    terrace: "Terraza",
    garden: "Jardin",
    plot: "Terreno",
    propTitle: '{subType} {searchType} en {location}',
    propTitleShort: '{subType} en {location}',
    notAvailable: "No disponible",
    notFound: 'Página no encontrada',
    emptyProperties: "No se encontraron propiedades, por favor contactar con el agente para más información.",
    linkExpired: "Ups, este enlace ha caducado. Contacte con su agente inmobiliario para recibir un enlace de propiedades actualizado.",
    newDevelopment: "Nueva promoción",
    agencyRaiiNo: "Número de registro de la agencia",
    home: {
        verifyYourURLHere: "Verifique su URL aquí. Para hacer esto, inicie sesión en WhatsApp en su navegador web, luego copie la URL de WhatsApp aquí.",
        pasteUrlHere: "Pegar URL aquí",
        verifyUrl: "Verificar URL",
        whatIsPropertyViewer: "¿Qué es Property-Viewer.com?",
        thisIsWebsiteIsUsed: "Los agentes inmobiliarios utilizan este sitio web para enviar propiedades a sus clientes. Es un producto de {resalesonlinecom} En caso de duda, hable con el agente que le envió la lista de propiedades.",
        howDoesItWork: "¿Cómo funciona?",
        inThePast: "En el pasado, los agentes enviaban documentos PDF a sus clientes. Sin embargo, estos no son fáciles de ver en muchos dispositivos. Por lo tanto, creamos {propertyviewercom} para que el cliente pueda ver más fácilmente las propiedades que se les envían.",
        createAShortList: "El agente creará una breve lista de propiedades. Cuando compartan estas propiedades con usted, recibirá un vínculo. Al hacer clic en este enlace le mostrará las propiedades."
    },

    list: {
        propertyTitle: "Haga clic aquí para ver la información de esta propiedad"
    },

    searchType: {
        ForSale: "en venta",
        ShortTermRental: "en alquiler de corta temporada",
        LongTermRental: "en alquiler de larga temporada",
    },

    rsStatus: {
        Available: "Disponible",
        Listing: "Pendiente",
        UnderOffer: "Oferta Hecha",
        SaleAgreed: "Venta Acordada",
        OffMarket: "Retirada del Mercado",
        Sold: "Vendida",
        Rented: "Alquilado"
    },

    details: {
        average: "Media",
        averagePricesFor: "Media de precios para ",
        showFullER: "Mostrar Completo",
        thisProperty: "Esta propiedad",
        infoNotAvailable: "Esta información no está disponible.",
        feesEnergyRating: "Tarifas, Calificación de Energía",
        fees: "Gastos",
        ibiFees: "Gastos IBI",
        basuraTax: "Gastos Basura",
        communityFees: "Gastos de Comunidad",
        associatedCosts: "Costos asociados",
        energyRating: "Calificación Energética",
        securityDeposit: "Fianza",
        energyCertificate: "Certificado energético",
        energyCertificatePending: "El certificado energético está pendiente",
        energyConsumption: "Consumo de Energia",
        co2: "CO₂",
        co2PerYear: "kgCO₂/m² por año",
        kwhPerYear: "kWh/m² por año",
        other: "Otros",
        buildYear: "Año de construcción",
        forSale: "En venta",
        sold: "Vendido",
        longTerm: "Larga estancia",
        shortTermHigh: "Corta estancia, temporada Alta",
        shortTermLow: "Corta estancia, temporada Baja",
        allFeatures: "Todas las características",
        aproxLocation: "Localización aproximada",
        rentalLicenseRef: "Ref. Licencia de Alquiler",
        notIncludeExpensesAndTaxes: "El precio no incluye gastos ni tributos. Los gastos adicionales al precio por parte del comprador son: Vivienda nueva/1ª Transmission Impuesto sobre el Valor Añadido (IVA) 10% (o 4% en caso de vivienda protegida); o en el caso de 2ª y/o Posteriores Transmisiones Impuesto Sobre Transmisiones (ITP) 7%. Además, la formalización en documento público del contrato de compraventa (escrituras) está sujeta a AJD Actos Jurídicos Documentados 1,2% sobre el precio de la compra, gastos de inscripción en el registro y notaría. Tiene a su disposición copia de la correspondiente ficha informativa de esta propiedad en nuestra oficina según Decreto 218/2005 de 11 de octubre.",
        listingAgent: "Agencia Listadora",
        agencyInformation: "Información de la Agencia",
    },
};
