import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Header from "./partials/Header";
import CustomHead from './partials/CustomHead';
import {useSelector} from "react-redux";

function NoMatch() {

    const l = useSelector((state) => {
        return state.localizations.l;
    });

    return (
        <div className="container-fluid">
            <CustomHead properties={{
                  title: l.notFound,
            }}/>
            <Header />
            <Container>
                <Row>
                    <Col>
                        <div className="image-not-found">
                            <div className={'sorry'}>
                                <span>{l.emptyProperties}</span>
                            </div>
                            {/* <div className={'thank-you'}>{l.thankYou}</div> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default NoMatch;
