export default {
    parseURL: (url) => {

        var parser = document.createElement('a'),
            searchObject = {},
            queries, split, i;

        // Let the browser do the work
        parser.href = url;

        // Convert query string to object
        queries = parser.search.replace(/^\?/, '').split('&');
        for( i = 0; i < queries.length; i++ ) {
            split = queries[i].split('=');
            searchObject[split[0]] = split[1];
        }

        return {
            protocol: parser.protocol,
            host: parser.host,
            hostname: parser.hostname,
            port: parser.port,
            pathname: parser.pathname,
            search: parser.search,
            searchObject: searchObject,
            hash: parser.hash
        };

    },

    getUrl: (uuid, rsId = "") => {
        let indexPath = process.env.REACT_APP_ROUTE_INDEX;

        if (uuid && rsId) {
            indexPath = indexPath + "?uuid=" + uuid + '&rsId=' + rsId;
        } else if (uuid) {
            indexPath = indexPath + "?uuid=" + uuid;
        }
        return indexPath;
    }
}
