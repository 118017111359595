import React from 'react';
import { AccordionContext, useAccordionToggle } from 'react-bootstrap';

export default ({ children, eventKey, callback }) => {
    const currentEventKey = React.useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
    const isCurrentEventKey = currentEventKey === eventKey;
    return (
        <div className="cardheader" onClick={decoratedOnClick}>
            { isCurrentEventKey && <i className="icon icon-toggle icon-proe-up-medium"/> }
            { !isCurrentEventKey && <i className="icon icon-toggle icon-proe-down-medium"/> }
            { children }
        </div>
    );
}
