import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Header from "./partials/Header";
import CustomHead from './partials/CustomHead';
import {useSelector} from "react-redux";

function NoMatch() {

    const l = useSelector((state) => {
        return state.localizations.l;
    });

    return (
        <div className="container-fluid">
            <CustomHead properties={{
                  title: l.notFound,
            }}/>
            <Header />
            <Container>
                <Row>
                    <Col>
                        <div className="image-not-found">
                            {/* <img className={'img-404'} src={notFound404} alt="Not Found" /> */}
                            <div className={'sorry'}>
                                <span>{l.sorry} {l.cantFind} {l.lookingFor}</span>
                            </div>
                            {/* <div className={'thank-you'}>{l.thankYou}</div> */}
                            <div className={'contact'}>
                                <span>{l.pleaseContact}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default NoMatch;
