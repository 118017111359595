import React, {useEffect} from 'react';
import { useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import _ from 'underscore';
import Header from './partials/Header';
import ListCarouseImage from './partials/ListCarouselImage';
import request from "../agent";
import configApis from "../configs/apis";
import DetailBuilt from './partials/Detail/DetailBuilt';
import DetailHeader from './partials/Detail/DetailHeader';
import DetailDescription from './partials/Detail/DetailDescription';
import CustomHead from "./partials/CustomHead";
import AccordionLocation from "./partials/Accordion/AccordionLocation";
import AccordionFER from './partials/Accordion/AccordionFER';
import AccordionFeatures from './partials/Accordion/AccordionFeatures';
import AccordionAgency from './partials/Accordion/AccordionAgency';
import common from "../constants/common";

const PropertyDetails = () =>
{
    const properties = useSelector((state) => {
        return state.properties;
    });
    const currentProperty = properties.currentProperty;
    const agency = useSelector((state) => {
        return state.agencies.agency;
    });
    const searchInfo = useSelector((state) => {
        return state.properties.searchInfo;
    });
    const l = useSelector((state) => {
        return state.localizations.l;
    });

    const locsForFooter = ['Almería', 'Granada', 'Jaén', 'Málaga', 'Córdoba', 'Sevilla', 'Huelva', 'Cádiz'];

    useEffect(() => {
        if ( typeof agency === "object" && typeof searchInfo === "object" ) {
            let uuid = properties.uuid;
            let rsId = currentProperty.RsId;
            if (uuid.length < 11 || uuid.startsWith('RP-') === false) { // no audit for R+ for now
                let auditStatus = JSON.parse(localStorage.getItem('audit_'+uuid));
                if (auditStatus === null) {
                    auditStatus = [];
                }
                if (_.indexOf(auditStatus, rsId) === -1) {
                    function audit() {
                        // Add audit of property-viewer.com
                        request.post(configApis.audit_url + '/gateway-audit/action', {
                            RsId: currentProperty.RsId,
                            SearchType: searchInfo.SearchType,
                            SearchVersion: searchInfo.SearchEngine === common.SEARCH_ENGINE_CORE_API
                                ? common.SEARCH_VERSION_21 : common.SEARCH_VERSION_20,
                            ContactSecureId: agency.contactSecureId
                        });
                        auditStatus.push(rsId);
                        localStorage.setItem('audit_'+uuid, JSON.stringify(auditStatus));
                    }
                    audit();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProperty]);

    const title = l.formatString(l.propTitle, {
      subType: currentProperty.IsDevelopment ? l.newDevelopment : currentProperty.SubType,
      searchType: l.searchType[currentProperty.SearchType],
      location: currentProperty.RsLocation
    });

    return (
        <div className="container-fluid">
            <Header />

            <CustomHead properties={{
                title: title,
                description: currentProperty.Description,
                image: currentProperty.Photos[0]
            }}/>

            <section id="property-details">
                <div className="prop-header fixed-top position-sticky">
                    <Container className="h-100">
                        <DetailHeader property={currentProperty} />
                    </Container>
                </div>

                <div className="prop-body">
                    <Container>
                        <Row>
                            <Col lg={8}>
                                <ListCarouseImage property={ currentProperty } />

                                <DetailBuilt property={ currentProperty } />

                                <DetailDescription property={ currentProperty } />

                                <Row className="prop-accor">
                                    <Col>
                                        <div className="accordion-cont fees-energy">
                                            <AccordionFER property={ currentProperty } />
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="prop-accor">
                                    <Col>
                                        <div className="accordion-cont avg-prices">
                                            <AccordionFeatures property={ currentProperty } />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={4}>
                                <Row className="property-right-col area-agency">
                                    <Col>
                                        <div className="accordion-cont">
                                            <AccordionAgency property={ currentProperty } />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="property-right-col area-location">
                                    <Col>
                                        <div className="accordion-cont">
                                            <AccordionLocation property={ currentProperty } />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {(currentProperty.SearchType === "ForSale" && locsForFooter.includes(currentProperty.RsProvince)) && 
                    <div className="prop-tail">
                        { l.details.notIncludeExpensesAndTaxes }
                    </div>
                }
            </section>
        </div>
    );
}

export default PropertyDetails;
