import React from 'react';
import {Helmet} from "react-helmet";

const CustomHead = (props) => {

  const properties = props.properties;

  return <Helmet>
    {properties.type && (
        <meta property="og:type" content={properties.type} />
    )}
    {properties.title && (
        <meta property="og:title" content={properties.title} />
    )}
    {properties.description && (
        <meta property="og:description" content={properties.description} />
    )}
    {properties.image && (
        <meta property="og:image" content={properties.image} />
    )}
    {properties.description && (
        <meta name="description" content={properties.description} />
    )}
    {properties.title && (
        <title>{properties.title}</title>
    )}
  </Helmet>
};

export default CustomHead;
