export default {
    title: "{agencyName} s egenskaper",
    in: "in",
    propertiesFound: "eiendommer funnet",
    toDay: "I dag",
    days: "dager",
    week: "Uke",
    year: "År",
    month: "Måned",
    prev: "Forrige",
    next: "Neste",
    backToList: "Tilbake til listen",
    sorry: "Sorry , we",
    cantFind: "finner ikke",
    lookingFor: "siden du leter etter ...",
    thankYou: "TAKK FOR AT DU BESØKER VÅRE NETTSIDE",
    pleaseContact: "Kontakt agent for en ny URL.",
    ForSale: "SALG",
    ShortTermRental: "Kort leie",
    LongTermRental: "Lang husleie",
    showMore: "Show More",
    showLess: "Vis mindre",
    price: "Pris",
    pricePer: "Pris per",
    built: "Bygg",
    floorSpace: "Interiøret gulvplass",
    terrace: "Terrasse",
    garden: "Garden",
    plot: "Tomt",
    propTitle: '{subType} {searchType} på {location}',
    propTitleShort: '{subType} på {location}',
    notAvailable: "Ikke tilgjengelig",
    notFound: 'Siden ble ikke funnet',
    emptyProperties: "Ingen eiendommer ble funnet. Kontakt agent for mer informasjon.",
    linkExpired: "Oops, denne koblingen er utløpt. Kontakt din eiendomsmegler for å motta en oppdatert eiendomslenke.",
    newDevelopment: "Ny utvikling",
    agencyRaiiNo: "Byråets registreringsnummer",
    home: {
        verifyYourURLHere: "Bekreft URL-en din her. For å gjøre dette, logg inn på WhatsApp i nettleseren din, og kopier deretter URL-en fra WhatsApp til hit.",
        pasteUrlHere: "Lim inn URL her",
        verifyUrl: "Bekreft URL",
        whatIsPropertyViewer: "Hva er Property-Viewer.com?",
        thisIsWebsiteIsUsed: "Dette nettstedet brukes av eiendomsmeglere til å sende eiendommer til sine kunder. Det er et produkt fra {resalesonlinecom} Hvis du er i tvil, snakk med agenten som sendte eiendomslisten din til deg.",
        howDoesItWork: "Hvordan fungerer det?",
        inThePast: "Tidligere sendte agenter PDF-dokumenter til sine kunder. Disse er imidlertid ikke enkle å vise på mange enheter. Derfor opprettet {propertyviewercom} slik at klienten lettere kan vise egenskaper som sendes til dem.",
        createAShortList: "Agenten oppretter en kort liste over egenskaper. Når de deler disse eiendommene med deg, vil du motta en kobling. Ved å klikke på denne linken vil vise deg egenskapene."
    },

    list: {
        propertyTitle: "Klikk her for å vise informasjon for denne egenskapen"
    },

    searchType: {
        ForSale: "for salg",
        ShortTermRental: "for korttidsleie",
        LongTermRental: "for langtidsleie",
    },

    rsStatus: {
        Available: "Tilgjengelig",
        Listing: "Oppføringen",
        UnderOffer: "Under tilbud",
        SaleAgreed: "Salg avtalt",
        OffMarket: "Utenfor markedet",
        Sold: "Solgt",
        Rented: "Leid"
    },

    details: {
        average: "Gjennomsnitt",
        averagePricesFor: "Gjennomsnittlige priser for",
        showFullER: "Vis full",
        thisProperty: "Denne eiendommen",
        infoNotAvailable: "Denne informasjonen er ikke tilgjengelig.",
        feesEnergyRating: "Gebyrer, energivurdering",
        fees: "Gebyrer",
        ibiFees: "IBI-avgifter",
        basuraTax: "Basura Skatt",
        communityFees: "Fellesskap avgifter",
        associatedCosts: "	Tilknyttede kostnader",
        energyRating: "Energiklassifisering",
        securityDeposit: "Depositum",
        energyCertificate: "Energiattest",
        energyCertificatePending: "Energisertifikatet venter",
        energyConsumption: "Energiforbruk",
        co2: "CO₂",
        co2PerYear: "kgCO₂/m² per år",
        kwhPerYear: "kWh/m² per år",
        other: "Andre",
        buildYear: "Byggeår",
        forSale: "Til salgs",
        sold: "Solgt",
        longTerm: "Langsiktig",
        shortTermHigh: "Short term high",
        shortTermLow: "Kortsiktig lav",
        allFeatures: "Alle funksjoner",
        aproxLocation: "Omtrentlig plassering",
        listingAgent: "Oppføring agent",
        notIncludeExpensesAndTaxes: "El precio no incluye gastos ni tributos. Los gastos adicionales al precio por parte del comprador son: Vivienda nueva/1ª Transmission Impuesto sobre el Valor Añadido (IVA) 10% (o 4% en caso de vivienda protegida); o en el caso de 2ª y/o Posteriores Transmisiones Impuesto Sobre Transmisiones (ITP) 7%. Además, la formalización en documento público del contrato de compraventa (escrituras) está sujeta a AJD Actos Jurídicos Documentados 1,2% sobre el precio de la compra, gastos de inscripción en el registro y notaría. Tiene a su disposición copia de la correspondiente ficha informativa de esta propiedad en nuestra oficina según Decreto 218/2005 de 11 de octubre.",
        rentalLicenseRef: "Verhuur licentie Ref",
        agencyInformation: "Informasjon om byrå",
    },
};