import React from 'react';
import { useSelector } from "react-redux";
import { Accordion, Card } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import ContextAwareToggle from "../ContextAwareToggle";

export default (props) => {
    let accordionOpenStatus = "0";
    if ( isMobile ) {
        accordionOpenStatus = "1";
    }
    const l = useSelector((state) => {
        return state.localizations.l;
    });
    const agency = useSelector((state) => {
        return state.agencies.agency;
    });

    // Change to use larger logo
    const agencyLogo = agency.logo.replace("agencies/1/1", "agencies/1/2");

    return (
        <Accordion defaultActiveKey={accordionOpenStatus}>
            <Card>
                <ContextAwareToggle as={ Card.Header } eventKey="0">
                    <h2><i className="icon icon-proe-agent"/> {l.details.agencyInformation}</h2>
                </ContextAwareToggle>
                <Accordion.Collapse eventKey="0">
                    <div>
                        <div className="agency-name text-center">
                            <strong>{agency.name}</strong>
                        </div>
                        {agency.raiiReg && (
                        <div className="raiiNo text-center">
                            <span title={l.agencyRaiiNo}>{agency.raiiNumber}</span>
                        </div>
                        )}
                        <div className="agency-logo text-center">
                            <img src={agencyLogo} alt={agency.name} />
                        </div>
                        <div className="agency-info">
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <i className="icon icon-proe-phone"/>
                                    <a href={"tel:" + agency.phone}>{agency.phone}</a>
                                </li>
                                <li className="list-group-item">
                                    <i className="icon icon-proe-email"/>
                                    <a href={"mailto:" + agency.email}>{agency.email}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}
