export default {
    title: "{agencyName} s egenskaper",
    in: "in",
    propertiesFound: "fastigheter hittades",
    toDay: "Idag",
    days: "dagar",
    week: "Vecka",
    year: "År",
    month: "Månad",
    prev: "Prev",
    next: "Nästa",
    backToList: "Tillbaka till listan",
    sorry: "Sorry , we",
    cantFind: "kan inte hitta",
    lookingFor: "sidan du letar efter ..",
    thankYou: "TACK FÖR ATT DU BESÖKER VÅR WEBBPLATS",
    pleaseContact: "Kontakta agenten för en ny webbadress.",
    ForSale: "SALE",
    ShortTermRental: "Short Rent",
    LongTermRental: "Long Rent",
    showMore: "Visa mer",
    showLess: "Visa mindre",
    price: "Pris",
    pricePer: "Pris per",
    built: "Bygg",
    floorSpace: "Användbara",
    terrace: "Terrass",
    garden: "Trädgård",
    plot: "tomt",
    propTitle: '{subType} {searchType} in {location}',
    propTitleShort: '{subType} in {location}',
    notAvailable: "Ej tillgängligt",
    notFound: 'Sidan hittades inte',
    emptyProperties: "No properties were found, please contact your Agent for more information.",
    linkExpired: "Oj, den här länken har gått ut. Kontakta din mäklare för att få en uppdaterad fastighetslänk.",
    newDevelopment: "Ny utveckling",
    agencyRaiiNo: "Byråns registreringsnummer",
    home: {
        verifyYourURLHere: "Verifiera din URL här. För att göra detta, logga in på WhatsApp i din webbläsare och kopiera sedan URL-adressen från WhatsApp till här.",
        pasteUrlHere: "Klistra in URL här",
        verifyUrl: "Verifiera URL",
        whatIsPropertyViewer: "Vad är Property-Viewer.com?",
        thisIsWebsiteIsUsed: "Denna webbplats används av fastighetsmäklare för att skicka fastigheter till sina kunder. Den är en produkt från {resalesonlinecom} Om du är osäker, vänligen tala med agenten som skickade din fastighetslista till dig.",
        howDoesItWork: "Hur fungerar det?",
        inThePast: "Tidigare skulle agenter skicka PDF-dokument till sina kunder. Dessa är dock inte lätta att visa på många enheter. Därför har vi {propertyviewercom} så att klienten lättare kan visa egenskaper som skickas till dem.",
        createAShortList: "Agenten kommer att skapa en kort lista med egenskaper. När de delar dessa egenskaper med dig får du en länk. Om du klickar på den här länken visas egenskaperna."
    },

    list: {
        propertyTitle: "Klicka här om du vill visa information för den här egenskapen"
    },

    searchType: {
        ForSale: "till salu",
        ShortTermRental: "för korttidsuthyrning",
        LongTermRental: "för långtidsuthyrning",
    },

    rsStatus: {
        Available: "Tillgänglig",
        Listing: "Lista",
        UnderOffer: "Under Erbjudande",
        SaleAgreed: "Försäljning överenskommen",
        OffMarket: "Utanför marknaden",
        Sold: "Såld",
        Rented: "Hyrd"
    },

    details: {
        average: "Genomsnitt",
        averagePricesFor: "Genomsnittspriser för",
        showFullER: "Visa hela",
        thisProperty: "Denna fastighet",
        infoNotAvailable: "Denna information är inte tillgänglig.",
        feesEnergyRating: "Avgifter, energiklass",
        fees: "Avgifter",
        ibiFees: "IBI-avgifter",
        basuraTax: "Basura Tax",
        communityFees: "Community Fees",
        associatedCosts: "Tillhörande kostnader",
        energyRating: "Energi betyg",
        securityDeposit: "Deposition",
        energyCertificate: "Energy certificate",
        energyCertificatePending: "Energicertifikatet väntar",
        energyConsumption: "Energiförbrukning",
        co2: "CO₂",
        co2PerYear: "kgCO₂/m² per år",
        kwhPerYear: "kWh/m² per år",
        other: "Andra",
        buildYear: "Byggår",
        forSale: "Till salu",
        sold: "Såld",
        longTerm: "Long term",
        shortTermHigh: "Short term high",
        shortTermLow: "Kort sikt låg",
        allFeatures: "Alla funktioner",
        aproxLocation: "Aprox. Location",
        listingAgent: "Listing agent",
        notIncludeExpensesAndTaxes: "El precio no incluye gastos ni tributos. Los gastos adicionales al precio por parte del comprador son: Vivienda nueva/1ª Transmission Impuesto sobre el Valor Añadido (IVA) 10% (o 4% en caso de vivienda protegida); o en el caso de 2ª y/o Posteriores Transmisiones Impuesto Sobre Transmisiones (ITP) 7%. Además, la formalización en documento público del contrato de compraventa (escrituras) está sujeta a AJD Actos Jurídicos Documentados 1,2% sobre el precio de la compra, gastos de inscripción en el registro y notaría. Tiene a su disposición copia de la correspondiente ficha informativa de esta propiedad en nuestra oficina según Decreto 218/2005 de 11 de octubre.",
        rentalLicenseRef: "Uthyrningslicens Ref",
        agencyInformation: "Information från byrån",
    },
};