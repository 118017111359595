import actionsType from '../constants/actionsType';
import localization from '../localization';
import cookieUtils from '../utils/cookie';
import commonConstant from '../constants/common';

let currentLanguage = cookieUtils.get('language');
if (currentLanguage === "") {
    currentLanguage = commonConstant.LANGUAGE_DEFAULT;
}
localization.setLanguage(currentLanguage);
const initialState = {
    l: localization,
    currentLanguage
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionsType.LOAD_LOCALIZATION:
            localization.setLanguage(action.payload);
            return {
                ...state,
                currentLanguage: action.payload,
                l: localization
            };

        default:
            return state;
    }
};
