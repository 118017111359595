import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button, InputGroup, FormControl, FormLabel, Alert } from 'react-bootstrap';
import request from "../../agent";
import configApis from "../../configs/apis";
import urlUtils from '../../utils/url';

function VerifyURL() {
    const [ url, setUrl ] = useState(null);
    const [ vaildUrl, setVaildUrl ] = useState(false);
    const [ verified, setVerified ] = useState(false);

    const l = useSelector((state) => {
        return state.localizations.l;
    });

    const verifyUrlAction = async (urlNeedToVerify) => {
        let parseUrl = urlUtils.parseURL(urlNeedToVerify);
        let uuid = parseUrl.searchObject.uuid?parseUrl.searchObject.uuid:"";
        let rsId = parseUrl.searchObject.rsId?parseUrl.searchObject.rsId:"";

        setVerified(true);

        if (!uuid) {
            setVaildUrl(false);
            return;
        }

        try {
            let { properties } = await request.get(configApis.properties_url.replace('{:id}', uuid));
            if (properties !== undefined && properties.items.length > 0) {

                if (uuid !== "" || rsId !== "") {
                    let property = [];
                    if (rsId !== "") {
                        property = properties.items.filter(item => item.RsId === parseInt(rsId));
                    }

                    if ((property.length && rsId !== "") || (uuid !== "" && rsId === "")) {
                        setVaildUrl(true);
                        return;
                    }
                }
            }

        } catch (e) {
            // Throw an error here.
            console.error(e);
        }

        setVaildUrl(false);

        return;
    }

    return <Container>
        <div className='verify-url'>
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <FormLabel className={'verify-label'}>{l.home.verifyYourURLHere}</FormLabel>
                        <FormControl
                            placeholder={l.home.pasteUrlHere}
                            aria-label={l.home.pasteUrlHere}
                            aria-describedby="basic-addon2"
                            onChange={ (e) => {
                                if (e.target.value !== "") {
                                    setUrl(e.target.value);
                                }
                            }} />
                        <InputGroup.Append>
                        <Button variant="outline-secondary"
                                onClick={() => {
                                verifyUrlAction(url);
                                return false;
                            }}>{l.home.verifyUrl}</Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>

            { verified && vaildUrl && (
                <Row>
                    <Col>
                        <Alert variant={'success'}>
                            <span>This is a valid URL. Click here to see your list of properties: </span>
                            <Button className="asLink" onClick={ () => { window.location = url } }>
                                [View Properties]
                            </Button>
                        </Alert>
                    </Col>
                </Row>
            )}

            { verified && !vaildUrl && (
                <Row>
                    <Col>
                        <Alert variant={'warning'}>
                            <span>This is an invalid URL. Do not use it. </span>
                        </Alert>
                    </Col>
                </Row>
            )}
        </div>
    </Container>
}

export default VerifyURL;
