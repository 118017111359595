import actionsType from '../constants/actionsType';

const initialState = {
    isDetail: false,
    uuid: null,
    paramLang: null,
    properties: [],
    showPlotSqmSubTypes: [],
    currentProperty: {},
    featuresTranslation: [],
    propertyTypesTranslation: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionsType.LOAD_PARAMATERS:
            return {
                ...state,
                rsId: action.payload.rsId,
                uuid: action.payload.uuid,
                paramLang: action.payload.paramLang
            };

        case actionsType.LOAD_SEARCH_INFO:
            return {
                ...state,
                searchInfo: action.payload
            };

        case actionsType.LOAD_PROPERTIES:
            return {
                ...state,
	            properties: action.payload.properties,
                showPlotSqmSubTypes: action.payload.showPlotSqmSubTypes
            };

        case actionsType.PROPERTY_DETAIL:
            return {
                ...state,
                isDetail: true,
                currentProperty: action.payload
            };

        case actionsType.PROPERTY_LIST:
            return {
                ...state,
                isDetail: false
            };
        
        case actionsType.TRANSLATE_PROPERTY_FEATURES:
            return {
                ...state,
                featuresTranslation: action.payload
            };

        case actionsType.TRANSLATE_PROPERTY_TYPES:
            return {
                ...state,
                propertyTypesTranslation: action.payload
            };

        default:
            return state;
    }
};
