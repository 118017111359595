import LocalizedStrings from 'react-localization';
import en from './localizations/en';
import es from './localizations/es';
import de from './localizations/de';
import fr from './localizations/fr';
import nl from './localizations/nl';
import dk from './localizations/dk';
import ru from './localizations/ru';
import se from './localizations/se';
import pl from './localizations/pl';
import no from './localizations/no';
import tr from './localizations/tr';
import sa from './localizations/sa';
import fi from './localizations/fi';
import hu from './localizations/hu';
import sv from './localizations/sv';

const localization = new LocalizedStrings({
    en,
    es,
    de,
    fr,
    nl,
    dk,
    ru,
    se,
    pl,
    no,
    tr,
    sa,
    fi,
    hu,
    sv
});

export default localization;
