import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Row, Col, Accordion, Card } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import ContextAwareToggle from "../ContextAwareToggle";
import common from "../../../constants/common";

function FeaturesItems(props) {
    const [ showMore, setShowMore ] = useState(false);
    const maxShow = 5;

    const l = useSelector((state) => {
        return state.localizations.l;
    });

    let featuresList = null;
    if (props.category.Items.length > 0) {
        featuresList = props.category.Items.map((feature, i) =>
            <li className={ (i > maxShow && !showMore) ? 'hidden' : '' } key={ `feature${i}` }>
                <i className="icon icon-proe-check"/> { feature }
            </li>
        );

        if (props.category.Items.length > maxShow) {
            featuresList.push(
                <li key="featureShowMore" className={ !showMore ? 'showmore' : 'hidden' }
                    onClick={ () => setShowMore(true) }>
                    { l.showMore } <i className="icon icon-proe-angle-down"/>
                </li>
            );
        }

    } else {
        featuresList = <li>—</li>;
    }

    return (
        <Col sm={6} md={4} lg={4} xl={3}>
            <div className="category">{ props.category.Category }</div>
            <ul className="items">
                { featuresList }
            </ul>
        </Col>
    );
}

function translateFeatures(translateData, features, lng) {
    const translatedFeatures = features.map(feature => {
        const category = feature.Category;
        const newItems = feature.Items.map(item => {
            return translateData[category]?.['child']?.[item]?.[lng] ?? item;
        })
        const newCategory = translateData[category]?.['value']?.[lng] ?? category;

        return {
            ...feature,
            Items:newItems,
            Category:newCategory
        }
    })

    return translatedFeatures;
}

function FeaturesCategory(props) {
    const features = props.features;
    const categoriesList = features.map((feature, i) =>
        <FeaturesItems key={ `category${i}` } category={ feature } />
    );

    return (
        <Row className="features">
            { categoriesList }
        </Row>
    );
}

export default (props) => {
    const l = useSelector((state) => {
        return state.localizations.l;
    });
    
    const translateData = useSelector(state => {
        return state.properties.featuresTranslation;
    });

    const searchInfo = useSelector(state => {
        return state.properties.searchInfo;
    });

    const lng = useSelector(state => {
        return state.localizations.currentLanguage;
    });

    let accordionOpenStatus = "0";
    if ( isMobile ) {
        accordionOpenStatus = "1";
    }

    const currentProperty = props.property;

    let translatedFeatures = currentProperty.Features;
    if (searchInfo.SearchEngine === common.SEARCH_ENGINE_RESALES_PLUS) {
        translatedFeatures = translateFeatures(translateData, currentProperty.Features, lng)
    }

    return (
        <Accordion defaultActiveKey={accordionOpenStatus}>
            <Card>
                <ContextAwareToggle as={ Card.Header } eventKey="0">
                    <h2>
                        <i className="icon icon-proe-check-list"/> { l.details.allFeatures }
                    </h2>
                </ContextAwareToggle>
                <Accordion.Collapse eventKey="0">
                    <FeaturesCategory features={ translatedFeatures } />
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};
