export default {
    title: "{agencyName}'s properties",
    in: "in",
    propertiesFound: "properties found",
    toDay: "Today",
    days: "days",
    week: "Week",
    year: "Year",
    month: "Month",
    prev: "Prev",
    next: "Next",
    backToList: "Back to List",
    sorry: "Sorry , we",
    cantFind: "can't find",
    lookingFor: "the page you're looking for...",
    thankYou: "THANK YOU FOR VISITING OUR WEBSITE",
    pleaseContact: "Please contact the Agent for a new URL.",
    ForSale: "SALE",
    ShortTermRental: "Short Rent",
    LongTermRental: "Long Rent",
    showMore: "Show More",
    showLess: "Show Less",
    price: "Price",
    pricePer: "Price per",
    built: "Build",
    floorSpace: "Int. Floor Space",
    terrace: "Terrace",
    garden: "Garden",
    plot: "Plot",
    propTitle: '{subType} {searchType} in {location}',
    propTitleShort: '{subType} in {location}',
    notAvailable: "Not Available",
    notFound: 'Page not found',
    emptyProperties: "No properties were found, please contact your Agent for more information.",
    linkExpired: "Oops, this link has expired. Contact your estate agent to receive an updated property link.",
    newDevelopment: "New Development",
    agencyRaiiNo: "Agency's registration number",
    home: {
        verifyYourURLHere: "Verify your URL here. To do this, login to WhatsApp on your web browser, then copy the URL from WhatsApp to here.",
        pasteUrlHere: "Paste URL here",
        verifyUrl: "Verify URL",
        whatIsPropertyViewer: "What is Property-Viewer.com?",
        thisIsWebsiteIsUsed: "This website is used by Estate Agents to send properties to their clients. It is a product of {resalesonlinecom} If in doubt, please speak to the Agent who sent your property list to you.",
        howDoesItWork: "How does it work?",
        inThePast: "In the past, Agents would send PDF documents to their clients. However, these are not easy to view on many devices. Therefore we created {propertyviewercom} so that the client can more easily view properties sent to them.",
        createAShortList: "The Agent will create a short list of properties. When they share these properties with you, you will receive a link. Clicking on this link will show you the properties."
    },

    searchType: {
        ForSale: "for sale",
        ShortTermRental: "for short term rent",
        LongTermRental: "for long term rent",
    },

    rsStatus: {
        Available: "Available",
        Listing: "Listing",
        UnderOffer: "Under Offer",
        SaleAgreed: "Sale Agreed",
        OffMarket: "Off Market",
        Sold: "Sold",
        Rented: "Rented"
    },

    list: {
        propertyTitle: "Click here to view information for this property"
    },

    details: {
        average: "Average",
        averagePricesFor: "Average prices for ",
        showFullER: "Show Full",
        thisProperty: "This property",
        infoNotAvailable: "This information is not available.",
        feesEnergyRating: "Fees, Energy Rating",
        fees: "Fees",
        ibiFees: "IBI Fees",
        basuraTax: "Basura Tax",
        communityFees: "Community Fees",
        associatedCosts: "Associated Costs",
        securityDeposit: "Security Deposit",
        energyRating: "Energy Rating",
        energyCertificate: "Energy certificate",
        energyCertificatePending: "The energy certificate is pending",
        energyConsumption: "Energy Consumption",
        co2: "CO₂",
        co2PerYear: "kgCO₂/m² per year",
        kwhPerYear: "kWh/m² per year",
        other: "Other info",
        buildYear: "Build year",
        forSale: "For sale",
        sold: "Sold",
        longTerm: "Long term",
        shortTermHigh: "Short term high",
        shortTermLow: "Short term low",
        allFeatures: "All features",
        aproxLocation: "Aprox. Location",
        notIncludeExpensesAndTaxes: "The price does not include expenses and taxes. The additional costs to the price to be paid by the buyer are: New home/1st Transfer Value Added Tax (VAT) 10% (or 4% in the case of protected housing); or in the case of 2nd and/or Subsequent Transfers, Transfer Tax (ITP) 7%. In addition, the formalisation in public document of the purchase contract (deeds) is subject to AJD Stamp Duty (AJD Actos Jurídicos Documentados) 1.2% on the purchase price, registration and notary fees. You have at your disposal a copy of the corresponding information sheet of this property in our office according to Decree 218/2005 of 11 October.",
        rentalLicenseRef: "Rental License Ref",
        listingAgent: "Listing agent",
        agencyInformation: "Agency Information",
    },
};
