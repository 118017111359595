import React from 'react';
import { useSelector } from "react-redux";
import { Accordion, Card } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import ContextAwareToggle from "../ContextAwareToggle";

export default (props) => {

    const showMap = true;
    let accordionOpenStatus = "0";
    if ( isMobile ) {
        accordionOpenStatus = "1";
    }
    const currentProperty = props.property;
    const l = useSelector((state) => {
        return state.localizations.l;
    });

    return (
        <Accordion defaultActiveKey={accordionOpenStatus}>
            <Card>
                <ContextAwareToggle as={ Card.Header } eventKey="0">
                    <h2><i className="icon icon-proe-themify-15"/>  { l.details.aproxLocation }</h2>
                </ContextAwareToggle>
                <Accordion.Collapse eventKey="0">
                    <div>
                        <meta itemProp="address" content="Sant Feliu de Guíxols, Costa Brava" />
                        { showMap && <iframe style={{ border:0 }}
                            allowFullScreen="" frameBorder="0"
                            width="100%" height="250"
                            title={ `${currentProperty.RsLocation},${currentProperty.RsArea}` }
                            src={ `https://www.google.com/maps/embed/v1/place?key=AIzaSyC35C66QY50Cirolb2lPN3Gamj9qv5_a6Y&q=${currentProperty.RsLocation},${currentProperty.RsArea}&zoom=10&language=en` } />
                        }
                    </div>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}
