import actionsType from './constants/actionsType';

const actions = {
    onLoad: () => {
        return {
            type: actionsType.ON_LOAD//,
            // payload: agency
        }
    },

    loadParameters: (params) => {
        return {
            type: actionsType.LOAD_PARAMATERS,
            payload: params
        }
    },

    loadSearchInfo: (searchInfo) => {
      return {
        type: actionsType.LOAD_SEARCH_INFO,
        payload: searchInfo
      }
    },

    loadLocalization: (localization) => {
        return {
            type: actionsType.LOAD_LOCALIZATION,
            payload: localization
        }
    },

    loadAgency: (agency) => {
        return {
            type: actionsType.LOAD_AGENCY,
            payload: agency
        }
    },

    loadProperties: (properties) => {
        return {
            type: actionsType.LOAD_PROPERTIES,
            payload: properties
        }
    },

    setDetail: (item) => {
        return {
            type: actionsType.PROPERTY_DETAIL,
            payload: item
        }
    },

    backToList: () => {
        return {
            type: actionsType.PROPERTY_LIST,
            payload: {
                isDetail: false
            }
        }
    },

    loadPropertyFeatures: (payLoad) => {
        return {
            type: actionsType.TRANSLATE_PROPERTY_FEATURES,
            payload: payLoad
        }
    },

    loadPropertyTypes: (payLoad) => {
        return {
            type: actionsType.TRANSLATE_PROPERTY_TYPES,
            payload: payLoad
        }
    }
}

export default actions;
