import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';

import Languages from './Languages';
import actions from '../../actions';
import urlUtils from '../../utils/url';

function Header(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const agency = useSelector(state => state.agencies.agency);
    const properties = useSelector(state => state.properties);
    const uuid = properties.uuid;

    const backToList = () => {
        history.push(urlUtils.getUrl(uuid));
        dispatch(actions.backToList());
    };

    return <div className={'header'}>
        <Row>
            <Col xs={10}>
                <div className="logo" onClick={ () => backToList() }>
                { props.srcLogo !== undefined && (
                    <img className="logo" alt="Resale-Online" src={props.srcLogo} />
                )}
                { agency && agency.logo !== undefined && (
                    <img className="logo" alt={agency.name} src={agency.logo} />
                )}
                </div>
            </Col>

            <Col xs={2}>
                { props.languages !== undefined && <Languages langs={props.languages} />}
            </Col>
        </Row>
    </div>
}

export default Header;
