export default {
    title: "Nieruchomości {agencyName}",
    in: "w",
    propertiesFound: "znalezione nieruchomości",
    toDay: "Dzisiaj",
    days: "dni",
    week: "Tydzień",
    year: "Rok",
    month: "Miesiąc",
    prev: "Poprzedni",
    next: "Dalej",
    backToList: "Powrót do listy",
    sorry: "Przepraszamy, my",
    cantFind: "nie mogę znaleźć",
    lookingFor: "strona, której szukasz ...",
    thankYou: "DZIĘKUJEMY ZA ODWIEDZENIE NASZEJ STRONY INTERNETOWEJ",
    pleaseContact: "Skontaktuj się z agentem w celu uzyskania nowego adresu URL.",
    ForSale: "SPRZEDAŻ",
    ShortTermRental: "Short Rent",
    LongTermRental: "Long Rent",
    showMore: "Pokaż więcej",
    showLess: "Pokaż mniej",
    price: "Cena",
    pricePer: "Cena za",
    built: "Zbuduj",
    floorSpace: "Wewnętrznych powierzchni",
    terrace: "Taras",
    garden: "Ogród",
    plot: "Działka",
    propTitle: '{subType} {searchType} in {location}',
    propTitleShort: '{subType} in {location}',
    notAvailable: "Niedostępne",
    notFound: 'Nie znaleziono strony',
    emptyProperties: "Nie znaleziono żadnych nieruchomości. Skontaktuj się z przedstawicielem, aby uzyskać więcej informacji.",
    linkExpired: "Ups, ten link wygasł. Skontaktuj się ze swoim agentem nieruchomości, aby otrzymać zaktualizowany link do nieruchomości.",
    newDevelopment: "Nowy rozwój",
    agencyRaiiNo: "Numer rejestracyjny agencji",
    home: {
        verifyYourURLHere: "Zweryfikuj tutaj swój adres URL. Aby to zrobić, zaloguj się do WhatsApp w przeglądarce internetowej, a następnie skopiuj adres URL z WhatsApp tutaj.",
        pasteUrlHere: "Tutaj wklej URL",
        verifyUrl: "Zweryfikuj URL",
        whatIsPropertyViewer: "Co to jest Property-Viewer.com?",
        thisIsWebsiteIsUsed: "Ta witryna jest używana przez agentów nieruchomości do wysyłania nieruchomości do swoich klientów. Jest to produkt witryny {resalesonlinecom}. W razie wątpliwości prosimy o kontakt z Agent, który przesłał Ci listę nieruchomości",
        howDoesItWork: "Jak to działa?",
        inThePast: "W przeszłości agenci wysyłali dokumenty PDF do swoich klientów. Jednak nie są one łatwe do wyświetlenia na wielu urządzeniach. W związku z tym utworzyliśmy {propertyviewercom}, dzięki czemu klient może łatwiej wyświetlać właściwości wysłane do nich.",
        createAShortList: "Agent utworzy krótką listę właściwości. Gdy udostępnią ci te właściwości, otrzymasz link. Kliknięcie tego linku spowoduje wyświetlenie właściwości."
    },

    searchType: {
        ForSale: "na sprzedaż",
        ShortTermRental: "na wynajem krótkoterminowy",
        LongTermRental: "na wynajem długoterminowy",
    },

    rsStatus: {
        Available: "Dostępny",
        Listing: "Aukcji",
        UnderOffer: "W ofercie",
        SaleAgreed: "Sprzedaż uzgodniona",
        OffMarket: "Poza rynkiem",
        Sold: "Sprzedany",
        Rented: "Wynajmowane"
    },

    list: {
        propertyTitle: "Kliknij tutaj, aby wyświetlić informacje dotyczące tej właściwości"
    },

    details: {
        average: "Średnia",
        averagePricesFor: "Średnie ceny za",
        showFullER: "Pokaż cały",
        thisProperty: "Ta właściwość",
        infoNotAvailable: "Ta informacja jest niedostępna.",
        feesEnergyRating: "Opłaty, Ocena zużycia energii",
        fees: "Opłaty",
        ibiFees: "Opłaty IBI",
        basuraTax: "Basura Podatek",
        communityFees: "Opłaty wspólnotowe",
        associatedCosts: "Koszty towarzyszące",
        energyRating: "Klasa energetyczna",
        securityDeposit: "Kaucja gwarancyjna",
        energyCertificate: "Certyfikat energetyczny",
        energyCertificatePending: "Certyfikat energetyczny oczekuje",
        energyConsumption: "Zużycie energii",
        co2: "CO₂",
        co2PerYear: "kgCO₂/m² rocznie",
        kwhPerYear: "kWh/m² rocznie",
        other: "Inni",
        buildYear: "Rok budowy",
        forSale: "Na sprzedaż",
        sold: "Sprzedane",
        longTerm: "Długoterminowe",
        shortTermHigh: "Krótkotrwała wysoka",
        shortTermLow: "Krótkoterminowe niskie",
        allFeatures: "Wszystkie funkcje",
        aproxLocation: "Przybliżona lokalizacja",
        listingAgent: "Agent notowań",
        notIncludeExpensesAndTaxes: "El precio no incluye gastos ni tributos. Los gastos adicionales al precio por parte del comprador son: Vivienda nueva/1ª Transmission Impuesto sobre el Valor Añadido (IVA) 10% (o 4% en caso de vivienda protegida); o en el caso de 2ª y/o Posteriores Transmisiones Impuesto Sobre Transmisiones (ITP) 7%. Además, la formalización en documento público del contrato de compraventa (escrituras) está sujeta a AJD Actos Jurídicos Documentados 1,2% sobre el precio de la compra, gastos de inscripción en el registro y notaría. Tiene a su disposición copia de la correspondiente ficha informativa de esta propiedad en nuestra oficina según Decreto 218/2005 de 11 de octubre.",
        rentalLicenseRef: "Wypożyczenie licencji ref",
        agencyInformation: "Informacje o agencji",
    },
};