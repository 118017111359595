import React, {useState} from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useSelector } from "react-redux";
import common from '../../../constants/common';

export default (props) => {
    const currentProperty = props.property;
    const propDesc = currentProperty.Description;
    const [ descExpand, setDescExpand ] = useState(false);
    let propDescTooLong = false;
    let propRta = "";
    let propDescHead = "";
    let propDescTail = "";
    let ellipsis = "";
    const descEllipsisLength = 400;
    const l = useSelector((state) => {
        return state.localizations.l;
    });
    const descShowMore = () => {
        setDescExpand(true);
    };

    const descShowLess = () => {
        setDescExpand(false);
    };
    if (propDesc && propDesc.length > descEllipsisLength) {
        propDescTooLong = true;
        propDescHead = propDesc.substring(0, descEllipsisLength);
        propDescTail = propDesc.substring(descEllipsisLength);
        ellipsis = "...";
    } else {
        propDescHead = propDesc;
    }

    if (currentProperty.SearchType === common.SEARCH_TYPE_FOR_RENT_S && currentProperty.RsRentalLicenseRef !== "") {
        propRta = <span>{l.details.rentalLicenseRef}: {currentProperty.RsRentalLicenseRef}<br /><br /></span>;
    }

    return (
        <>
        <Row className="prop-desc preserved-break-line">
            <Col>
                { propRta }
                { propDescHead }
                { propDescTooLong && !descExpand && ellipsis }
                { propDescTooLong && descExpand && propDescTail }
                { propDescTooLong && !descExpand &&
                    <Button className="asLink" onClick={ descShowMore }>{ l.showMore }</Button>
                }
                { propDescTooLong && descExpand &&
                    <Button className="asLink" onClick={ descShowLess }>{ l.showLess }</Button>
                }
            </Col>
        </Row>
        <Row className="prop-rental-premises">
            {currentProperty.RentalPremises && currentProperty.RentalPremises.map((feature, index) => <Col key={index}><i className='icon icon-proe-check'/><span>{feature}</span></Col>)}
        </Row>
        </>
    );
}
