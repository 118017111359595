import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useQuery } from './hooks/routers';
import Home from './components/Home';
import ListProperties from './components/ListProperties';
import './resources/scss/App.scss';
import actions from "./actions";

const App = () => {
    const dispatch = useDispatch();
    const params = useQuery();
    const uuid = params.get('uuid');
    const rsId = params.get('rsId');
    const paramLang = params.get('lang');
    useEffect(() => {
        dispatch(actions.loadParameters({uuid, rsId, paramLang}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid, rsId, paramLang]);

    if (!uuid && !rsId) {
        return <Home />
    }

    return <ListProperties />;
};

export default App;
