export default {
    title: "{agencyName} tulajdonságai",
    in: "itt",
    propertiesFound: "tulajdonságok találhatók",
    toDay: "Ma",
    days: "napok",
    week: "Hét",
    year: "Év",
    month: "Hónap",
    prev: "Előző",
    next: "Következő",
    backToList: "Vissza a listához",
    sorry: "Sajnáljuk, mi",
    cantFind: "nem találom",
    lookingFor: "a keresett oldal ...",
    thankYou: "KÖSZÖNJÜK, HOGY LÁTOGATTAK OLDALUNKAT",
    pleaseContact: "Kérjük, vegye fel a kapcsolatot az ügynökkel új URL-címért.",
    ForSale: "AKCIÓ",
    ShortTermRental: "Rövid bérlet",
    LongTermRental: "Long Rent",
    showMore: "Show More",
    showLess: "Kevesebbet mutat",
    price: "Ár",
    pricePer: "Ár per",
    built: "Build",
    floorSpace: "Int. alapterület",
    terrace: "Terasz",
    garden: "kert",
    plot: "telek",
    propTitle: '{subType} {searchType} itt {location}',
    propTitleShort: '{subType} itt {location}',
    notAvailable: "Not Available",
    notFound: 'Az oldal nem található',
    emptyProperties: "Ominaisuuksia ei löytynyt, ota yhteyttä edustajaasi saadaksesi lisätietoja.",
    linkExpired: "Hoppá, ez a link lejárt. Vegye fel a kapcsolatot ingatlanügynökkel, hogy megkapja a frissített ingatlan linket.",
    newDevelopment: "Új fejlesztés",
    agencyRaiiNo: "Az Ügynökség nyilvántartási száma",
    home: {
        verifyYourURLHere: "Itt ellenőrizze az URL-t. Ehhez jelentkezzen be a WhatsApp-ra a webböngészőjében, majd másolja az URL-t a WhatsApp-ból ide.",
        pasteUrlHere: "Illessze ide az URL-t",
        verifyUrl: "URL igazolása",
        whatIsPropertyViewer: "Mi az a Property-Viewer.com?",
        thisIsWebsiteIsUsed: "Az ingatlanközvetítők ezt a weboldalt használják ingatlanok elküldéséhez ügyfeleiknek. Ez a {resalesonlinecom} terméke. Ha kétségei vannak, kérjük, beszéljen a az ügynök, aki elküldte neked az ingatlanlistád.",
        howDoesItWork: "Hogyan működik?",
        inThePast: "A múltban az ügynökök PDF dokumentumokat küldtek ügyfeleiknek. Ezek azonban nem könnyű megtekinteni sok eszközön. Ezért létrehoztuk {propertyviewercom}, hogy az ügyfél könnyebben megtekinthesse a nekik küldött tulajdonságokat.",
        createAShortList: "Az ügynök létrehoz egy rövid listát a tulajdonságokról. Amikor megosztják önnel ezeket a tulajdonságokat, kap egy linket. Erre a linkre kattintva megmutatja a tulajdonságokat."
    },

    list: {
        propertyTitle: "Kattintson ide a tulajdonság adatainak megtekintéséhez"
    },

    searchType: {
        ForSale: "eladó",
        ShortTermRental: "rövid távra kiadó",
        LongTermRental: "hosszú távra kiadó",
    },

    rsStatus: {
        Available: "Beszerezhető",
        Listing: "Szövetszegély",
        UnderOffer: "Az ajánlat alatt",
        SaleAgreed: "Eladás megállapodott",
        OffMarket: "Piacon kívül",
        Sold: "Eladott",
        Rented: "Bérelt"
    },

    details: {
        average: "Átlagos",
        averagePricesFor: "Átlagos árak",
        showFullER: "Teljes megjelenítése",
        thisProperty: "Ez a tulajdonság",
        infoNotAvailable: "Ez az információ nem áll rendelkezésre.",
        feesEnergyRating: "Díjak, energiaminősítés",
        fees: "Díjak",
        ibiFees: "IBI díjak",
        basuraTax: "Basura adó",
        communityFees: "Közösségi díjak",
        associatedCosts: "Kapcsolódó költségek",
        energyRating: "Energetikai besorolás",
        securityDeposit: "Kaució",
        energyCertificate: "Energiatanúsítvány",
        energyCertificatePending: "Az energiatanúsítvány függőben van",
        energyConsumption: "Energiafogyasztás",
        co2: "CO₂",
        co2PerYear: "kgCO₂ / m² / év",
        kwhPerYear: "kWh / m² / év",
        other: "Mások",
        buildYear: "Építési év",
        forSale: "Eladó",
        sold: "Eladva",
        longTerm: "hosszú távú",
        shortTermHigh: "Rövid távon magas",
        shortTermLow: "Rövid távon alacsony",
        allFeatures: "Minden szolgáltatás",
        aproxLocation: "Aprox. Hely",
        listingAgent: "Listing agent",
        notIncludeExpensesAndTaxes: "El precio no incluye gastos ni tributos. Los gastos adicionales al precio por parte del comprador son: Vivienda nueva/1ª Transmission Impuesto sobre el Valor Añadido (IVA) 10% (o 4% en caso de vivienda protegida); o en el caso de 2ª y/o Posteriores Transmisiones Impuesto Sobre Transmisiones (ITP) 7%. Además, la formalización en documento público del contrato de compraventa (escrituras) está sujeta a AJD Actos Jurídicos Documentados 1,2% sobre el precio de la compra, gastos de inscripción en el registro y notaría. Tiene a su disposición copia de la correspondiente ficha informativa de esta propiedad en nuestra oficina según Decreto 218/2005 de 11 de octubre.",
        rentalLicenseRef: "Bérleti engedély Ref",
        agencyInformation: "Ügynökségi információk",
    },
};