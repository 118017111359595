export default {
    title: "eigenschappen van {agencyName}",
    in: "in",
    propertiesFound: "eigenschappen gevonden",
    toDay: "Vandaag",
    days: "dagen",
    week: "Week",
    year: "Jaar",
    month: "Maand",
    prev: "Vorige",
    next: "Volgende",
    backToList: "Terug naar lijst",
    sorry: "Sorry, wij",
    cantFind: "kan niet vinden",
    lookingFor: "de pagina die u zoekt ...",
    thankYou: "BEDANKT VOOR HET BEZOEKEN VAN ONZE WEBSITE",
    pleaseContact: "Neem contact op met de agent voor een nieuwe URL.",
    ForSale: "SALE",
    ShortTermRental: "Short Rent",
    LongTermRental: "Long Rent",
    showMore: "Meer weergeven",
    showLess: "Show Less",
    price: "Prijs",
    pricePer: "Prijs per",
    built: "Build",
    floorSpace: "Interieur vloeroppervlak",
    terrace: "Terras",
    garden: "Garden",
    plot: "Plot",
    propTitle: '{subType} {searchType} in {location}',
    propTitleShort: '{subType} in {location}',
    notAvailable: "Niet beschikbaar",
    notFound: 'Pagina niet gevonden',
    emptyProperties: "Er zijn geen eigendommen gevonden, neem contact op met uw makelaar voor meer informatie.",
    linkExpired: "Oeps, deze link is verlopen. Neem contact op met uw makelaar om een bijgewerkte woningkoppeling te ontvangen.",
    newDevelopment: "Nieuwe ontwikkeling",
    agencyRaiiNo: "Registratienummer van het agentschap",
    home: {
        verifyYourURLHere: "Verifieer je URL hier. Om dit te doen, log in op WhatsApp in je webbrowser, kopieer dan de URL van WhatsApp naar hier.",
        pasteUrlHere: "Plak URL hier",
        verifyUrl: "Verifieer URL",
        whatIsPropertyViewer: "Wat is Property-Viewer.com?",
        thisIsWebsiteIsUsed: "Deze website wordt gebruikt door makelaars om eigendommen naar hun klanten te sturen. Het is een product van {resalesonlinecom} Neem bij twijfel contact op met de agent die uw eigendommenlijst naar u heeft gestuurd.",
        howDoesItWork: "Hoe werkt het?",
        inThePast: "In het verleden stuurden agents PDF-documenten naar hun klanten. Deze zijn echter niet gemakkelijk te bekijken op veel apparaten. Daarom hebben we {propertyviewercom} gemaakt, zodat de klant gemakkelijker eigenschappen kan bekijken die naar hen worden verzonden.",
        createAShortList: "De agent maakt een korte lijst met eigenschappen. Wanneer ze deze eigenschappen met u delen, ontvangt u een link. Als u op deze link klikt, ziet u de eigenschappen."
    },

    list: {
        propertyTitle: "Klik hier om informatie voor deze accommodatie te bekijken"
    },

    searchType: {
        ForSale: "te koop",
        ShortTermRental: "voor korte termijn verhuur",
        LongTermRental: "voor lange termijn verhuur",
    },

    rsStatus: {
        Available: "Beschikbaar",
        Listing: "Aanbieding",
        UnderOffer: "Onder Aanbieding",
        SaleAgreed: "Verkoop overeengekomen",
        OffMarket: "Off-markt",
        Sold: "Verkocht",
        Rented: "Gehuurd"
    },

    details: {
        average: "Gemiddeld",
        averagePricesFor: "Gemiddelde prijzen voor",
        showFullER: "Show Full",
        thisProperty: "Deze eigenschap",
        infoNotAvailable: "Deze informatie is niet beschikbaar.",
        feesEnergyRating: "Vergoedingen, Energieklasse",
        fees: "vergoedingen",
        ibiFees: "IBI-kosten",
        basuraTax: "Basura Tax",
        communityFees: "Community Fees",
        associatedCosts: "Bijkomende kosten",
        energyRating: "	Energielabel",
        securityDeposit: "Waarborgsom",
        energyCertificate: "Energiecertificaat",
        energyCertificatePending: "Het energiecertificaat is in behandeling",
        energyConsumption: "Energieverbruik",
        co2: "CO₂",
        co2PerYear: "kgCO₂/m² per jaar",
        kwhPerYear: "kWh/m² per jaar",
        other: "Anderen",
        buildYear: "Bouwjaar",
        forSale: "Te koop",
        sold: "Verkocht",
        longTerm: "Lange termijn",
        shortTermHigh: "Korte termijn hoog",
        shortTermLow: "Korte termijn laag",
        allFeatures: "Alle functies",
        aproxLocation: "Aprox. Locatie",
        listingAgent: "Listing agent",
        notIncludeExpensesAndTaxes: "El precio no incluye gastos ni tributos. Los gastos adicionales al precio por parte del comprador son: Vivienda nueva/1ª Transmission Impuesto sobre el Valor Añadido (IVA) 10% (o 4% en caso de vivienda protegida); o en el caso de 2ª y/o Posteriores Transmisiones Impuesto Sobre Transmisiones (ITP) 7%. Además, la formalización en documento público del contrato de compraventa (escrituras) está sujeta a AJD Actos Jurídicos Documentados 1,2% sobre el precio de la compra, gastos de inscripción en el registro y notaría. Tiene a su disposición copia de la correspondiente ficha informativa de esta propiedad en nuestra oficina según Decreto 218/2005 de 11 de octubre.",
        rentalLicenseRef: "Verhuur licentie Ref",
        agencyInformation: "Informatie agentschap",
    },
};