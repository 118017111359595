export default {
    CURRENCY: {
        EUR: "€",
        USD: "$",
        GBP: "£",
        RUB: "₽",
        TRY: "₺",
        SAR: "﷼",
        VND: "₫"
    },
    DIMENSION: {
        m: "m²",
        ft: "ft²",
    }
}

/*eslint no-extend-native: ["error", { "exceptions": ["Number"] }]*/
Number.prototype.formatMoney = function(decPlaces, thouSeparator, decSeparator) {
    let n = this;
    decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces;
    decSeparator = decSeparator === undefined ? '.' : decSeparator;
    thouSeparator = thouSeparator === undefined ? ',' : thouSeparator;
    let sign = n < 0 ? '-' : '';
    let i = parseInt((n = Math.abs(+n || 0).toFixed(decPlaces))) + '';
    let j = 0; j = (j = i.length) > 3 ? j % 3 : 0;

    return (
        sign +
        (j ? i.substr(0, j) + thouSeparator : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator) +
        (decPlaces
            ? decSeparator +
              Math.abs(n - i)
                  .toFixed(decPlaces)
                  .slice(2)
            : '')
    );
};