import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import format from '../../../utils/format';
import DetailNavigator from './DetailNavigator';
import common from '../../../constants/common';
import { translatePropertyType } from '../../../utils/common';

export default (props) => {
    const currentProperty = props.property;

    const l = useSelector((state) => {
        return state.localizations.l;
    });

    const searchEngine = useSelector((state) => {
        return state.properties.searchInfo.SearchEngine;
    });

    const propertyTypeTranslateData = useSelector((state) => {
        return state.properties.propertyTypesTranslation;
    });

    const lng = useSelector(state => {
        return state.localizations.currentLanguage;
    });

    let subType = currentProperty.SubType;
    if (searchEngine === common.SEARCH_ENGINE_RESALES_PLUS) {
        subType = translatePropertyType(propertyTypeTranslateData, currentProperty.Type, subType, lng);
    }

    const title = l.formatString(l.propTitle, {
        subType: currentProperty.IsDevelopment ? l.newDevelopment : subType,
        searchType: l.searchType[currentProperty.SearchType],
        location: currentProperty.RsLocation,
    });

    let rsIdText = 'R' + currentProperty.RsId;

    let aRef = null;
    if (currentProperty.SearchType === 'ForSale' && currentProperty.RsAgencyRef !== '') {
        aRef = currentProperty.RsAgencyRef;
    } else if (currentProperty.SearchType === 'LongTermRental' && currentProperty.RsAgencyRentalRef !== '') {
        aRef = currentProperty.RsAgencyRentalRef;
    } else if (currentProperty.SearchType === 'ShortTermRental' && currentProperty.RsAgencyRentalShortRef !== '') {
        aRef = currentProperty.RsAgencyRentalShortRef;
    }
    const showARef = currentProperty.OwnProperty && aRef !== null;
    const beds = parseInt(currentProperty.RsBeds, 10);
    const bedsTo = parseInt(currentProperty.RsBedsTo, 10);
    const baths = parseInt(currentProperty.RsBaths, 10);
    const bathsTo = parseInt(currentProperty.RsBathsTo, 10);

    return (
        <Row>
            <Col className="navigator" lg={{ order: 'last', span: 4 }}>
                <Row>
                    <Col className="ref">
                        <span>
                            Ref: {showARef === false && <strong>{rsIdText}</strong>}
                            {showARef && <strong>{aRef}</strong>}
                        </span>
                    </Col>
                </Row>
                <DetailNavigator />
            </Col>

            <Col className="prop-name" lg={{ order: 'first', span: 8 }}>
                <h1>{title}</h1>
                <p className="indetails">
                    <span className="price">
                        {format.formatCurrency(currentProperty.Price, currentProperty.Currency)}
                        {currentProperty.Price < currentProperty.PriceTo &&
                            ' - ' + format.formatCurrency(currentProperty.PriceTo, currentProperty.Currency)}
                        {currentProperty.SearchType === 'LongTermRental'
                            ? '/' + l.month
                            : currentProperty.SearchType === 'ShortTermRental'
                            ? '/' + l.week
                            : ''}
                    </span>

                    {(beds > 0 && bedsTo >= 0) || (beds === 0 && bedsTo > 0) ? (
                        <span className="bedbath">
                            <i className="icon icon-proe-bedroom font-icon" />
                            <span>
                                {beds}
                                {beds < bedsTo && ' - ' + bedsTo}
                            </span>
                        </span>
                    ) : (
                        ''
                    )}

                    {(baths > 0 && bathsTo >= 0) || (baths === 0 && bathsTo > 0) ? (
                        <span className="bedbath">
                            <i className="icon icon-proe-bathroom font-icon" />
                            <span>
                                {baths}
                                {baths < bathsTo && ' - ' + bathsTo}
                            </span>
                        </span>
                    ) : (
                        ''
                    )}
                </p>
            </Col>
        </Row>
    );
};
