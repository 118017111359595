export default {
    title: "Свойства {agencyName}",
    in: "in",
    propertiesFound: "свойства найдены",
    toDay: "Сегодня",
    days: "дни",
    week: "Неделя",
    year: "Год",
    month: "Месяц",
    prev: "Предыдущая",
    next: "Далее",
    backToList: "Вернуться к списку",
    sorry: "Извините, мы",
    cantFind: "не могу найти",
    lookingFor: "страница, которую вы ищете ...",
    thankYou: "СПАСИБО ЗА ПОСЕЩЕНИЕ НАШЕГО ВЕБ-САЙТА",
    pleaseContact: "Пожалуйста, свяжитесь с агентом для получения нового URL.",
    ForSale: "ПРОДАЖА",
    ShortTermRental: "Краткосрочная аренда",
    LongTermRental: "Долгосрочная аренда",
    showMore: "Показать больше",
    showLess: "Показать меньше",
    price: "Цена",
    pricePer: "Цена за",
    built: "Сборка",
    floorSpace: "Интерьер площадь",
    terrace: "Терраса",
    garden: "Сад",
    plot: "Сюжет",
    propTitle: '{subType} {searchType} в {location}',
    propTitleShort: '{subType} в {location}',
    notAvailable: "Недоступно",
    notFound: 'Страница не найдена',
    emptyProperties: "Ничего не найдено. Свяжитесь с вашим агентом для получения дополнительной информации.",
    linkExpired: "К сожалению, эта ссылка истекла. Свяжитесь со своим агентом по недвижимости, чтобы получить обновленную ссылку на недвижимость.",
    newDevelopment: "Новая разработка",
    agencyRaiiNo: "Регистрационный номер агентства",
    home: {
        verifyYourURLHere: "Подтвердите здесь свой URL. Для этого войдите в WhatsApp в своем веб-браузере, затем скопируйте URL-адрес из WhatsApp сюда.",
        pasteUrlHere: "Вставить URL сюда",
        verifyUrl: "Подтвердить URL",
        whatIsPropertyViewer: "Что такое Property-Viewer.com?",
        thisIsWebsiteIsUsed: "Этот веб-сайт используется агентами по недвижимости для отправки объектов недвижимости своим клиентам. Это продукт {resalesonlinecom}. В случае сомнений обращайтесь к Агент, который отправил вам список вашего имущества",
        howDoesItWork: "Как это работает?",
        inThePast: "В прошлом агенты присылать PDF-документы своим клиентам. Тем не менее, это не легко просматривать на многих устройствах. Поэтому мы создали {propertyviewercom}, чтобы клиенту было легче просматривать свойства, отправленные им.",
        createAShortList: "Агент создаст краткий список свойств. Когда они поделятся этими свойствами с вами, вы получите ссылку. Нажав на эту ссылку покажет вам свойства."
    },

    list: {
        propertyTitle: "Нажмите здесь, чтобы просмотреть информацию для этого свойства"
    },

    searchType: {
        ForSale: "на продажу",
        ShortTermRental: "в краткосрочную аренду",
        LongTermRental: "в долгосрочную аренду",
    },

    rsStatus: {
        Available: "Доступный",
        Listing: "Распечатка",
        UnderOffer: "В предложении",
        SaleAgreed: "Продажа согласована",
        OffMarket: "Вне рынка",
        Sold: "Продавать",
        Rented: "Арендованный"
    },

    details: {
        average: "Средний",
        averagePricesFor: "Средние цены для",
        showFullER: "Показать полностью",
        thisProperty: "Это свойство",
        infoNotAvailable: "Эта информация недоступна.",
        feesEnergyRating: "Комиссия за энергопотребление",
        fees: "Сборы",
        ibiFees: "IBI Fees",
        basuraTax: "Basura Tax",
        communityFees: "Коммунальные сборы",
        associatedCosts: "Сопутствующие расходы",
        energyRating: "	Рейтинг энергоэффективности",
        securityDeposit: "Залог за сохранность имущества",
        energyCertificate: "Энергетический сертификат",
        energyCertificatePending: "Энергетический сертификат ожидает рассмотрения",
        energyConsumption: "Энергопотребление",
        co2: "CO₂",
        co2PerYear: "кгCO₂/м² в год",
        kwhPerYear: "кВтч/м² в год",
        other: "Другие",
        buildYear: "Год выпуска",
        forSale: "Продажа",
        sold: "Продано",
        longTerm: "Долгосрочный",
        shortTermHigh: "Кратковременный максимум",
        shortTermLow: "Краткосрочный минимум",
        allFeatures: "Все функции",
        aproxLocation: "Примерное местоположение",
        listingAgent: "Listing agent",
        notIncludeExpensesAndTaxes: "El precio no incluye gastos ni tributos. Los gastos adicionales al precio por parte del comprador son: Vivienda nueva/1ª Transmission Impuesto sobre el Valor Añadido (IVA) 10% (o 4% en caso de vivienda protegida); o en el caso de 2ª y/o Posteriores Transmisiones Impuesto Sobre Transmisiones (ITP) 7%. Además, la formalización en documento público del contrato de compraventa (escrituras) está sujeta a AJD Actos Jurídicos Documentados 1,2% sobre el precio de la compra, gastos de inscripción en el registro y notaría. Tiene a su disposición copia de la correspondiente ficha informativa de esta propiedad en nuestra oficina según Decreto 218/2005 de 11 de octubre.",
        rentalLicenseRef: "Лицензия на аренду Ref",
        agencyInformation: "Информация об агентстве",
    },
};