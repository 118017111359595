export default {
    title: "{agencyName}'ın özellikleri",
    in: "in",
    propertiesFound: "özellikler bulundu",
    toDay: "Bugün",
    days: "günler",
    week: "Hafta",
    year: "Year",
    month: "Ay",
    prev: "Önceki",
    next: "Sonraki",
    backToList: "Listeye Dön",
    sorry: "Üzgünüz, biz",
    cantFind: "bulunamıyor",
    lookingFor: "aradığınız sayfa ...",
    thankYou: "WEB SİTEMİZİ ZİYARET ETTİĞİNİZ İÇİN TEŞEKKÜR EDERİZ",
    pleaseContact: "Lütfen yeni bir URL için Temsilci ile iletişime geçin.",
    ForSale: "SATIŞ",
    ShortTermRental: "Kısa Kira",
    LongTermRental: "Uzun Kiralama",
    showMore: "Daha Fazla Göster",
    showLess: "Daha Az Göster",
    price: "Fiyat",
    pricePer: "Fiyat",
    built: "edilmiş yapı",
    floorSpace: "İç yüzey alanı",
    terrace: "Teras",
    garden: "Bahçe",
    plot: "Konu",
    propTitle: '{subType} {searchType}, {location}',
    propTitleShort: "{subType}, {location}' içinde",
    notAvailable: "Kullanılamaz",
    notFound: 'Sayfa bulunamadı',
    emptyProperties: "Emlak bulunamadı, daha fazla bilgi için lütfen Temsilcinizle iletişime geçin.",
    linkExpired: "Bu bağlantının süresi doldu. Güncellenmiş bir tesis bağlantısı almak için emlakçınızla iletişime geçin.",
    newDevelopment: "Yeni Gelişme",
    agencyRaiiNo: "Ajansın sicil numarası",
    home: {
        verifyYourURLHere: "URL'nizi burada doğrulayın. Bunu yapmak için, web tarayıcınızda WhatsApp'a giriş yapın, ardından URL'yi WhatsApp'tan buraya kopyalayın.",
        pasteUrlHere: "URL'yi buraya yapıştırın",
        verifyUrl: "URL'yi Doğrula",
        whatIsPropertyViewer: "Property-Viewer.com nedir?",
        thisIsWebsiteIsUsed: "Bu web sitesi Emlakçılar tarafından müşterilerine mülk göndermek için kullanılmaktadır. {resalesonlinecom}'un bir ürünüdür Şüpheniz varsa, lütfen ile konuşun emlak listenizi size gönderen Temsilci.",
        howDoesItWork: "Hur fungerar det?",
        inThePast: "Geçmişte, Aracılar müşterilerine PDF belgeleri gönderirdi. Ancak, bu birçok cihazda görüntülemek kolay değildir. Bu nedenle, istemcinin kendilerine gönderilen özellikleri daha kolay görüntüleyebilmeleri için {propertyviewercom} oluşturduk.",
        createAShortList: "Aracı, özelliklerin kısa bir listesini oluşturur. Bu özellikleri sizinle paylaştıklarında, bir bağlantı alırsınız. Bu bağlantıyı tıklattığınızda özellikleri gösterecektir."
    },

    list: {
        propertyTitle: "Bu özelliğin bilgilerini görüntülemek için burayı tıklatın"
    },

    searchType: {
        ForSale: "satılık",
        ShortTermRental: "kısa süreli kiralama için",
        LongTermRental: "uzun süreli kiralama için",
    },

    rsStatus: {
        Available: "Mevcut",
        Listing: "Liste",
        UnderOffer: "Teklif Altında",
        SaleAgreed: "Satış Kabul Edildi",
        OffMarket: "Piyasa Dışı",
        Sold: "Satılan",
        Rented: "Kirala"
    },

    details: {
        average: "Ortalama",
        averagePricesFor: "Ortalama fiyatlar",
        showFullER: "Tam Göster",
        thisProperty: "Bu mülk",
        infoNotAvailable: "Bu bilgi mevcut değil.",
        feesEnergyRating: "Ücretler, Enerji Derecelendirmesi",
        fees: "Ücretler",
        ibiFees: "IBI Ücretleri",
        basuraTax: "Basura Vergisi",
        communityFees: "Topluluk Ücretleri",
        associatedCosts: "İlişkili Maliyetler",
        energyRating: "Enerji Sınıfı",
        securityDeposit: "Depozito",
        energyCertificate: "Enerji sertifikası",
        energyCertificatePending: "Enerji sertifikası beklemede",
        energyConsumption: "Enerji Tüketimi",
        co2: "CO₂",
        co2PerYear: "kgCO₂ / m² / yıl",
        kwhPerYear: "kWh / m² / yıl",
        other: "Diğer",
        buildYear: "Yapım yılı",
        forSale: "Satılık",
        sold: "Satıldı",
        longTerm: "Uzun vadeli",
        shortTermHigh: "Kısa vadeli yüksek",
        shortTermLow: "Kısa vadeli düşük",
        allFeatures: "Tüm özellikler",
        aproxLocation: "Aprox. Konum",
        listingAgent: "Listing agent",
        notIncludeExpensesAndTaxes: "El precio no incluye gastos ni tributos. Los gastos adicionales al precio por parte del comprador son: Vivienda nueva/1ª Transmission Impuesto sobre el Valor Añadido (IVA) 10% (o 4% en caso de vivienda protegida); o en el caso de 2ª y/o Posteriores Transmisiones Impuesto Sobre Transmisiones (ITP) 7%. Además, la formalización en documento público del contrato de compraventa (escrituras) está sujeta a AJD Actos Jurídicos Documentados 1,2% sobre el precio de la compra, gastos de inscripción en el registro y notaría. Tiene a su disposición copia de la correspondiente ficha informativa de esta propiedad en nuestra oficina según Decreto 218/2005 de 11 de octubre.",
        rentalLicenseRef: "Kiralama Lisansı Ref",
        agencyInformation: "Ajans Bilgileri",
    },
};