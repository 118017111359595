import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Header from './partials/Header';
import CustomHead from './partials/CustomHead';
import VerifyURL from './partials/VerifyURL';
import languages from '../constants/languages';
import PropertyViewerInfo from './partials/PropertyViewerInfo';
import logoResaleOnline from '../resources/images/resales-online.svg';

function Home () {
    return (
        <div className="container-fluid">
            <div className="home">
                <CustomHead properties={{
                    'title': 'Resale-Online',
                    'description': '',
                    'image': ''
                }} />
                <Header srcLogo={logoResaleOnline} languages={languages}/>

                <Container>
                    <Row>
                        <Col className="well" xs={12} sm={12} md={{span: 8, offset: 2}} lg={{span: 8, offset: 2}}>
                            <VerifyURL />
                            <PropertyViewerInfo />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Home;
