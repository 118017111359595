import React from 'react';
import { useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';

function PropertyViewerInfo() {

    const l = useSelector((state) => {
        return state.localizations.l;
    });

    const thisIsWebsiteIsUsed = l.formatString(l.home.thisIsWebsiteIsUsed, {
      resalesonlinecom: <a target={"_blank"} rel={"noopener noreferrer"} href="https://www.resales-online.com">www.resales-online.com</a>
    });

    return <Container>
        <Row>
            <Col>
                <div className="what-is-pv">
                    <h1>{l.home.whatIsPropertyViewer}</h1>
                    <p>{thisIsWebsiteIsUsed}
                    </p>
                </div>
            </Col>
        </Row>
    </Container>
}

export default PropertyViewerInfo;
