export default [
    { code: 'en', name: 'English'},
    { code: 'es', name: 'Español'},
    { code: 'de', name: 'Deutsch'},
    { code: 'fr', name: 'Français'},
    { code: 'nl', name: 'Nederlands'},
    { code: 'dk', name: 'Dansk'},
    { code: 'ru', name: 'Русский'},
    { code: 'se', name: 'Svenska'},
    { code: 'pl', name: 'Polskim'},
    { code: 'no', name: 'Norsk'},
    { code: 'tr', name: 'Türkçe'},
    // { code: 'sa', name: 'عربى'},
    { code: 'fi', name: 'Finnish'},
    { code: 'hu', name: 'Hungarian'},
    // { code: 'vi', name: 'Vietnam'},
    // { code: 'sv', name: 'Swedish'}
];
